<template>
  <div class="anchor">
    <v-row
      v-if="accueil.resume_ventes"
      class="justify-center text-center ternary white--text"
    >
      <v-col cols="10">
        Dernières Transactions :
        <marquee-text :duration="60">
          {{ display }}
        </marquee-text>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" md="8" sm="12">
        <v-card class flat>
          <v-card-title>
            <h3 class="mx-auto title">ANNONCES A LA UNE</h3>
          </v-card-title>

          <v-row class="">
            <v-col
              class="py-0"
              v-for="annonce in annonces"
              :key="annonce.id"
              cols="12"
            >
              <div v-if="annonce.favorite" class="py-2">
                <annonce-short class="py-2" :annonce="annonce"></annonce-short>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AnnonceShort from "@/components/annonces/AnnonceShort";
import MarqueeText from "vue-marquee-text-component";

export default {
  data: () => ({
    annonces: [],
    annonce: "",
    display: "",
  }),
  props: {
    accueil: {},
  },
  components: {
    AnnonceShort,
    MarqueeText,
  },
  created() {
    // this.annonces = this.$store.getters.getAnnonces;
    this.$store
      .dispatch("bindAnnonces")
      .then(() => {
        this.annonces = this.$store.getters.getAnnonces;
        this.accueil = this.$store.getters.getAccueil;
        this.accueil.transactions.forEach((item) => {
          this.display += item.texte + " ⭐ ";
        });
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style></style>
