import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations, firestoreAction } from "vuexfire";
import createPersistedState from "vuex-persistedstate";
import db from "@/main";
import firebase from "firebase/app";
import "firebase/firestore";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    vide: null,
    layout: "public-layout",
    user: null,
    accueil: [],
    annonces: [],
    favorites: [],
    annonce: null,
    snackbar: {
      show: "",
      color: "",
      message: "",
      timeout: "",
    },
    loading: false,
  },

  getters: {
    getLayout(state) {
      return state.layout;
    },
    getUser(state) {
      return state.user;
    },
    getAccueil(state) {
      return state.accueil;
    },
    getAnnonces(state) {
      return state.annonces;
    },

    getAnnoncesFavorites(state) {
      state.favorites = state.annonces.filter((ann) => {
        ann.favorite == true;
      });
      // console.log("favorites : ", state.favorites);
      return state.favorites;
    },

    getAnnonce(state) {
      return state.annonce;
    },
    getSnackbar(state) {
      return state.snackbar;
    },
    getLoading(state) {
      return state.loading;
    },
  },

  mutations: {
    NULL(state) {
      state.vide = "";
    },
    SET_LAYOUT(state, layout) {
      state.layout = layout;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_ACCUEIL(state, accueil) {
      state.accueil = accueil;
    },
    SET_ANNONCES(state, annonces) {
      state.annonces = annonces;
      state.annonce = null;
    },
    SET_ANNONCE(state, annonce) {
      state.annonce = annonce;
    },
    SAVE_ANNONCE(state, annonce) {
      state.annonce = annonce;
    },
    FAVORITE() {
      console.log("favorite");
    },
    ONLINE() {
      console.log("publication");
    },
    DELETE_ANNONCE(state, id) {
      console.log("commit DELETE", id);
      console.log("commit DELETE", state);
    },
    ADD_ANNONCE(state) {
      // state.annonces.push(annonce);
      state.annonce = null;
    },
    ADD_IMAGE(state, url) {
      state.images.push(url);
    },
    SET_SNACKBAR(state, snackbar) {
      state.snackbar.show = true;
      state.snackbar.color = snackbar.color;
      state.snackbar.message = snackbar.message;
      state.timeout = 3000;
    },
    NO_SNACKBAR(state, snackbar) {
      state.snackbar = snackbar;
    },
    SET_LOADING(state) {
      state.loading = true;
    },
    NO_LOADING(state) {
      state.loading = false;
    },
    ...vuexfireMutations,
  },

  actions: {
    setUser({ commit }, user) {
      commit("SET_USER", user);
    },
    logOut({ commit }) {
      firebase.auth().signOut();
      commit("SET_USER", null);
    },

    bindAccueil: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef("accueil", db.collection("pages").doc("accueil"));
    }),

    updateAccueil({ commit }, accueil) {
      console.log("store : :", accueil);
      let accueilRef = db.collection("pages").doc("accueil");
      return accueilRef
        .update(accueil)
        .then(() => {
          commit("SET_ACCUEIL", accueil);
          commit("NO_LOADING");
          console.log("Accueil mis à jour!");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.log("Erreur mise à jour du document: ", error);
        });
    },

    bindAnnonces: firestoreAction(({ bindFirestoreRef }) => {
      return bindFirestoreRef("annonces", db.collection("annonces"));
    }),

    addImage({ commit }, annonce) {
      // console.log("payload : ", annonce);
      db.collection("annonces")
        .doc(annonce.id)
        .update(annonce)
        .then(() => {
          commit("SET_ANNONCE", annonce);
          this.$store.dispatch("noLoading");
          console.log("Image ajoutée!");
          this.$store.dispatch("setSnackbar", {
            color: "success",
            message: "L'image a bien été ajoutée à votre annonce",
          });
        })
        .catch((e) => {
          console.log("erreur store addImage :", e);
        });
    },

    deleteImage({ commit }, datas) {
      let imagesToDelete = db.collection("annonces").doc(datas.id);
      imagesToDelete.get().then((doc) => {
        for (let i = 0; i < doc.data().images.length; i++) {
          if (doc.data().images[i].nom == datas.nom) {
            let imageList = doc.data().images;
            imageList.splice(i, 1);
            imagesToDelete.update({
              images: imageList,
            });
            console.log("image supprimée de DB : ", i);
          }
        }
      });
      commit("NULL");
    },

    addPDF({ commit }, annonce) {
      db.collection("annonces")
        .doc(annonce.id)
        .update(annonce)
        .then(() => {
          commit("SET_ANNONCE", annonce);
          this.$store.dispatch("noLoading");
          console.log("PDF ajouté!");
          this.$store.dispatch("setSnackbar", {
            color: "success",
            message: "Le PDF a bien été ajouté à votre annonce",
          });
        })
        .catch((e) => {
          console.log("erreur store addPDF :", e);
        });
    },

    deletePDF({ commit }, id) {
      console.log("delete PDF :", id);
      let pdfToDelete = db.collection("annonces").doc(id);

      pdfToDelete.update({
        pdf: firebase.firestore.FieldValue.delete(),
      });
      commit("NULL");
    },

    saveAnnonce({ commit }, annonce) {
      db.collection("annonces")
        .doc(annonce.id)
        .update(annonce)
        .then(() => {
          commit("ADD_ANNONCE");
          console.log("Annonce updatée en DB");
        })
        .catch((e) => {
          console.log("Error ajout de document", e);
        });
    },

    deleteAnnonce({ commit }, id) {
      db.collection("annonces")
        .doc(id)
        .delete()
        .then(() => {
          commit("DELETE_ANNONCE", id);
          console.log("Annonce supprimée en DB");
        })
        .catch((e) => {
          console.log("Erreu r suppression d'annonce", e);
        });
    },

    newAnnonce({ commit }, annonce) {
      annonce.images = [];
      db.collection("annonces")
        .doc()
        .set(annonce)
        .then(() => {
          commit("ADD_ANNONCE", annonce);
          console.log("Annonce ajoutée en DB");
        })
        .catch((e) => {
          console.log("Error ajout de document", e);
        });
    },

    getAnnonce({ commit }, annonce_id) {
      this.state.annonces.forEach((item) => {
        if (item.id == annonce_id) {
          commit("SET_ANNONCE", item);
        }
      });
    },

    saveTransaction({ commit }, transaction) {
      db.collection("pages")
        .doc("accueil")
        .update({
          transactions: firebase.firestore.FieldValue.arrayUnion(transaction),
        })
        .then(() => {
          commit("NULL");
          console.log("Transaction ajoutée en DB");
        })
        .catch((e) => {
          console.log("Error ajout de transaction", e);
        });
    },

    newTransaction({ commit }, transaction) {
      db.collection("pages")
        .doc("accueil")
        .update({
          transactions: firebase.firestore.FieldValue.arrayUnion(transaction),
        })
        .then(() => {
          commit("NULL");
          console.log("Transaction ajoutée en DB");
        })
        .catch((e) => {
          console.log("Error ajout de transaction", e);
        });
    },

    deleteTransaction({ commit }, transaction) {
      db.collection("pages")
        .doc("accueil")
        .update({
          transactions: firebase.firestore.FieldValue.arrayRemove(transaction),
        })
        .then(() => {
          commit("NULL");
          console.log("Transaction supprimée en DB");
        })
        .catch((e) => {
          console.log("Error suppression de transaction", e);
        });
    },

    favorite({ commit }, id) {
      db.collection("annonces")
        .doc(id)
        .update({ favorite: true })
        .then(() => {
          commit("FAVORITE");
          console.log("Annonce ajoutée en favorites");
        })
        .catch((e) => {
          console.log("Error ajout favorite", e);
        });
    },

    noFavorite({ commit }, id) {
      db.collection("annonces")
        .doc(id)
        .update({ favorite: false })
        .then(() => {
          commit("FAVORITE");
          console.log("Annonce supprimée en favorites");
        })
        .catch((e) => {
          console.log("Error suppr favorite", e);
        });
    },

    online({ commit }, id) {
      db.collection("annonces")
        .doc(id)
        .update({ online: true })
        .then(() => {
          commit("ONLINE");
          console.log("Annonce Publiée");
        })
        .catch((e) => {
          console.log("Erreur publication annonce", e);
        });
    },

    offline({ commit }, id) {
      db.collection("annonces")
        .doc(id)
        .update({ online: false })
        .then(() => {
          commit("ONLINE");
          console.log("Annonce dépubliée");
        })
        .catch((e) => {
          console.log("Error depublication", e);
        });
    },

    setSnackbar({ commit }, snackbar) {
      commit("SET_SNACKBAR", snackbar);
    },
    noSnackbar({ commit }) {
      commit("NO_SNACKBAR", { show: false });
    },

    setLoading({ commit }) {
      commit("SET_LOADING");
    },
    noLoading({ commit }) {
      commit("NO_LOADING");
    },
  },

  plugins: [createPersistedState()],

  modules: {},
});
