<template>
  <div class="admin-home">
    <div v-if="loading">
      <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
    </div>

    <div v-if="!loading">
      <v-row class="align-stretch" justify="space-between">
        <v-col cols="12">
          <v-card class="ternary mb-3 pl-2 py-2">
            <span class="headline white--text">Vue Annonce</span>
            <v-btn class="accent float-right mr-3" small @click="back"
              >Retour</v-btn
            >
          </v-card>
        </v-col>

        <v-col clos="12" md="8">
          <!--    --------------------    TITRE   --------------     -->

          <v-card class="mb-3 pl-2 py-2">
            <h1 class="headline">
              <span class="warning--text">{{
                annonce.type == "Vente murs" ? "A Vendre : " : "A Louer : "
              }}</span
              ><span>{{ annonce.titre }} ({{ annonce.departement }}) </span>
            </h1>
            <v-spacer></v-spacer>
            <v-chip v-if="annonce.flash" color="error" small class="mr-3">
              {{ annonce.flash }}
            </v-chip>
            <h3 v-if="annonce.sous_titre" class="title">
              {{ annonce.sous_titre }}
            </h3>
          </v-card>

          <!--    --------------------    DESCRIPTION DU BIEN   --------------     -->

          <v-card class="mb-3 pl-2">
            <h2 class="headline font-weight-light">Description du bien</h2>
            <v-divider class="mb-3 mt-1"></v-divider>
            <span v-if="annonce.description" class="subtitle-1"
              >{{ annonce.description }}<br
            /></span>
            <span class="subtitle-1 font-weight-light"
              >Surface de {{ annonce.surface }} M² environ<br
            /></span>
            <span
              v-if="annonce.surface_details"
              class="subtitle-1 font-weight-light"
              >{{ annonce.surface_details }}<br
            /></span>
            <v-divider class="mb-3 mt-5"></v-divider>
            <div class="d-flex align-center pb-2">
              <span v-if="annonce.parking" class="subtitle-1">
                <v-icon class="mr-2">mdi-car-info</v-icon> Parking de
                {{ annonce.parking }} places
              </span>
              <span
                v-if="annonce.parking_details"
                class="subtitle-1 ml-2 mb-1"
                >{{ annonce.parking_details }}</span
              >
              <span v-if="annonce.autre_service" class="subtitle-1">
                <v-icon class="ml-5 mr-2">mdi-plus-box-outline</v-icon
                >{{ annonce.autre_service }}
              </span>
              <v-spacer></v-spacer>
              <v-btn
                v-if="annonce.plu"
                class="subtitle-2"
                :href="annonce.plu"
                target="blank"
                small
              >
                Consulter le P.L.U <v-icon class="ml-2">mdi-link</v-icon>
              </v-btn>
            </div>
          </v-card>

          <!--    --------------------    CONDITIONS FINANCIERES & JURIDIQUES   --------------     -->

          <v-card class="mb-3 pl-2">
            <h2 class="headline font-weight-light">
              Conditions financières & juridiques
            </h2>
            <v-divider class="mb-3 mt-1"></v-divider>
            <span v-if="annonce.prix" class="subtitle-1 font-weight-light"
              >Prix de vente : {{ annonce.prix }}€</span
            >
            <span
              v-if="annonce.prix_details"
              class="subtitle-1 font-weight-light"
            >
              {{ annonce.prix_details }}</span
            ><br v-if="annonce.prix" />

            <span
              v-if="annonce.charges_copro"
              class="subtitle-1 font-weight-light"
              >Charges de copropriété : {{ annonce.charges_copro }}€</span
            >
            <span
              v-if="annonce.charges_copro_details"
              class="subtitle-1 font-weight-light"
            >
              {{ annonce.charges_copro_details }}</span
            ><br v-if="annonce.charges_copro" />

            <span v-if="annonce.loyer" class="subtitle-2 font-weight-light"
              >Loyer : {{ annonce.loyer }}€</span
            >
            <span
              v-if="annonce.loyer_details"
              class="subtitle-1 font-weight-light"
            >
              {{ annonce.loyer_details }}</span
            ><br v-if="annonce.loyer" />

            <span v-if="annonce.prov" class="subtitle-2 font-weight-light"
              >Prov s/ charges : {{ annonce.prov }}€</span
            >
            <span
              v-if="annonce.prov_details"
              class="subtitle-1 font-weight-light"
            >
              {{ annonce.prov_details }}</span
            ><br v-if="annonce.prov" />

            <span
              v-if="annonce.droit_au_bail"
              class="subtitle-1 font-weight-light"
              >Droit au bail: {{ annonce.droit_au_bail }}€</span
            >
            <span
              v-if="annonce.droit_au_bail_details"
              class="subtitle-1 font-weight-light"
            >
              {{ annonce.droit_au_bail_details }}</span
            ><br v-if="annonce.droit_au_bail" />

            <span
              v-if="annonce.fond_de_commerce"
              class="subtitle-1 font-weight-light"
              >Droit au bail: {{ annonce.fond_de_commerce }}€</span
            >
            <span
              v-if="annonce.fond_de_commerce_details"
              class="subtitle-1 font-weight-light"
            >
              {{ annonce.fond_de_commerce_details }}</span
            ><br v-if="annonce.fond_de_commerce" />

            <span v-if="annonce.bail" class="subtitle-2 font-weight-light"
              >Bail : {{ annonce.bail }}<br
            /></span>

            <span v-if="annonce.taxe_fon" class="subtitle-1 font-weight-light"
              >Taxe foncière : {{ annonce.taxe_fonciere }}€</span
            >
            <span
              v-if="annonce.taxe_fon_details"
              class="subtitle-1 font-weight-light"
              >{{ annonce.taxe_fonciere_details }}</span
            ><br v-if="annonce.taxe_fon" />
            <v-divider class="mb-3 mt-5"></v-divider>
            <div class="subtitle-2 align-center d-flex justify-end mr-3">
              <p v-if="annonce.honoraires" class=" font-weight-light">
                Honoraires à la charge du preneur : {{ annonce.honoraires }}€ HT
              </p>
            </div>
          </v-card>

          <!--    --------------------    ENVIRONNEMENT   --------------     -->

          <v-card class="mb-3 pl-2">
            <h2 class="headline font-weight-light">Environnement</h2>
            <v-divider class="mb-3 mt-1"></v-divider>
            <div class="d-flex align-center pb-2">
              <span v-if="annonce.metro" class="subtitle-2">
                <v-icon class="mr-2">mdi-subway</v-icon> {{ annonce.metro }}
              </span>

              <span v-if="annonce.bus" class="subtitle-2">
                <v-icon class="ml-5 mr-2">mdi-bus-stop</v-icon>{{ annonce.bus }}
              </span>
            </div>
            <v-divider class="mb-3 mt-1"></v-divider>
            <div v-if="annonce.environnement">
              <span class="subtitle-1">{{ annonce.environnement }}</span>
            </div>
          </v-card>
        </v-col>

        <!--    --------------    GOOGLE MAP   --------------     -->

        <v-col cols="12" md="4" class="text-center">
          <p class="subtitle-2 font-weight-regular">
            <v-icon color="accent">mdi-alert</v-icon>
            extraire le lien de la carte en mode "Plan" pour avoir le marqueur
            rouge
          </p>

          <div v-if="annonce.map" class="iframe-container">
            <iframe
              :src="annonce.map"
              width="100%"
              height="100%"
              frameborder="1"
              style="border:0;"
              allowfullscreen="true"
              aria-hidden="false"
            ></iframe>
          </div>
        </v-col>
      </v-row>

      <!--    --------------------    IMAGES   --------------     -->

      <v-row>
        <v-col cols="12" class="">
          <v-card class="justify-center grey darken-4 py-5 d-flex flex-column">
            <div v-for="image in annonce.images" :key="image.index" class="">
              <v-img
                :src="image.url"
                class="ma-2"
                alt=""
                max-width="30%"
              ></v-img>
              <div class="text-left white--text ml-2">{{ image.titre }}</div>
              <div class="text-left white--text ml-2">ordre : {{ image.ordre }}</div>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="justify-center my-5">
        <v-col>
          <div class="justify-center text-center">
            <QRCanvas :options="options"></QRCanvas>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// import * as firebase from 'firebase/app'
// import "firebase/storage"
import { QRCanvas } from "qrcanvas-vue";

export default {
  data: () => ({
    annonces: [],
    annonce: null,
    loading: true,
    options: {
      cellSize: 8,
      data: "",
    },
  }),
  components: {
    QRCanvas,
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },

  async created() {
    this.$store.dispatch("noSnackbar");
    // console.log("created event");
    this.$store.commit("SET_LAYOUT", "admin-layout");
    this.loading = true;
    await this.$store
      .dispatch("getAnnonce", this.$route.params.annonce_id)
      .then(() => {
        this.loading = false;
        this.annonce = this.$store.getters.getAnnonce;
        this.options.data = "https://tcso.fr/annonce/" + this.annonce.id;
        const image = new Image();
        image.src =
          "https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/main%2Flogo-120-blanc-bg.png?alt=media&token=49af13dc-f3fd-4e5d-9d58-3464c58a8377";
        image.onload = () => {
          this.options = Object.assign({}, this.options, {
            logo: {
              image,
            },
          });
        };
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style lang="sass"></style>
