<template>
  <div class="home anchor">
    <v-row
      v-if="accueil.bandeau_actif"
      class="justify-center text-center primary white--text"
    >
      <v-col cols="12">
        <h2 class="font-weight-thin title d-none d-md-block">
          {{ accueil.bandeau }}
        </h2>
        <h2 class="font-weight-thin subtitle-2 d-xs-block d-sm-block d-md-none">
          {{ accueil.bandeau }}
        </h2>
      </v-col>
    </v-row>

    <!-- MOBILES -->

    <template>
      <v-parallax
        height="350"
        class="d-xs-block d-sm-block d-md-none"
        src="https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/main%2FO-toulousemob3.jpg?alt=media&token=6f6d7a2d-afd7-4c6f-b698-16265f7bd330"
      >
        <v-row class="justify-center text-center">
          <v-col cols="10" class="justify-center ternary transpa my-auto">
            <h1 class="my-3 subtitle-1">
              TRANSACTION & CONSEIL <br />
              SUD OUEST
            </h1>
            <v-img
              alt="TCSO logo"
              width="40%"
              class="mx-auto"
              src="https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/main%2Ftest-tcso-bleu.png?alt=media&token=bd5cbfb6-437a-443b-92bf-f01c1177c731"
            ></v-img>
            <h2 class="my-3 subtitle-2">{{ accueil.sous_titre }}</h2>
            <v-btn
              to="/annonces"
              small
              aria-label="Nos annonces"
              color="primary"
              >Toutes nos annonces
              <v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-parallax>
    </template>

    <!-- DESKTOP -->

    <template>
      <v-parallax
        class=" d-none d-md-block"
        height="560"
        src="https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/main%2FO-toulouse3.jpg?alt=media&token=5b03fb08-e6e3-4ee2-8b0a-53ed79d24c75"
      >
        <v-row class="justify-center text-center align-center d-none d-md-flex">
          <v-col
            cols="6"
            md="4"
            class="justify-center ternary transpa pb-5 elevation-4"
          >
            <h1 class="my-3 display-1">
              TRANSACTION & CONSEIL <br />
              SUD OUEST
            </h1>
            <v-img
              alt="TCSO logo"
              width="20%"
              class="mx-auto"
              src="https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/main%2Ftest-tcso-bleu.png?alt=media&token=bd5cbfb6-437a-443b-92bf-f01c1177c731"
            ></v-img>
            <h2 class="my-3 headline">{{ accueil.sous_titre }}</h2>
            <v-btn
              to="/annonces"
              small
              aria-label="Nos annonces"
              color="primary"
              >Toutes nos annonces
              <v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-parallax>
    </template>

    <!-- <v-row>
      <v-col cols="12">
        <video-player :options="videoOptions" />
      </v-col>
    </v-row> -->

    <annonces-favorites :accueil="this.accueil"></annonces-favorites>

    <v-row class="justify-center py-5">
      <v-col cols="12" sm="8">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="8" class="mx-auto mt-10 text-center">
        <v-card class="justify-center">
          <v-row class="mx-auto">
            <v-col cols="12" md="8" class="mx-auto text-center">
              <h3 class="mx-auto headline">PRESENTATION ET SERVICES</h3>
              <v-img
                alt="TCSO logo"
                width="10%"
                class="mx-auto mt-5 pa-5"
                src="https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/main%2Ftest-tcso-bleu.png?alt=media&token=bd5cbfb6-437a-443b-92bf-f01c1177c731"
              ></v-img>
              <h5 class="mx-auto title mt-10">
                Vendez votre affaire, 1 objectif plusieurs possibilités
              </h5>
              <p class="mx-auto mt-10 text-left">
                <span class="strong">"Céder son affaire"</span>
                revêt différentes réalités juridiques. Ainsi acheteur et
                vendeur, selon leur situation, pourront céder ou acquérir à leur
                choix leur fonds de commerce, leur droit au bail ou leur
                société.
              </p>
              <p class="mx-auto mt-3 text-left">
                N’hésitez pas à aller jeter un coup d’œil sur notre page dédiée.
              </p>
            </v-col>
          </v-row>
          <v-card-actions class="mx-auto justify-end"
            ><v-btn to="/informations#anchor" class="primary--text" text
              >En savoir plus</v-btn
            ></v-card-actions
          >

          <v-row class="mx-auto">
            <v-col cols="12" md="8" class="mx-auto text-center">
              <h5 class="mx-auto title mt-10">
                Présentation de la société, méthodes, réseaux
              </h5>
              <p class="mt-5 text-left">
                Fort de mon expérience dans les secteurs immobiliers et
                financiers j’ai décidé aujourd’hui de mettre tout mon
                savoir-faire et mon énergie afin qu’acheteurs et vendeurs,
                bailleurs et locataires puissent se rencontrer dans les
                meilleures conditions.
              </p>
              <p class="mt-5 text-left">
                Passionné de course à pied avec une attirance pour les courses
                longues distances en semi autonomie mon maître mot est la
                persévérance.
              </p>
            </v-col>
          </v-row>
          <v-card-actions class="mx-auto justify-end"
            ><v-btn to="/qui-sommes-nous#anchor" class="primary--text" text
              >En savoir plus</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AnnoncesFavorites from "@/components/annonces/AnnoncesFavorites";

// import VideoPlayer from "@/components/videos/VideoPlayer.vue";

export default {
  name: "Home",

  metaInfo: () => ({
    title: "Immobilier Commercial",
    collapse: true,
    meta: [
      {
        name: "description",
        content:
          "Transaction & Conseil Sud-Ouest est une agence immobilière spécialisée dans la transaction de locaux commerciaux (location simple avec ou sans droit au bail ou droit d’entrée, achat, vente, cession ou acquisition de murs commerciaux) pour professionnels dans le sud-ouest. Notre objectif est de mettre en relation bailleur et preneur, vendeur et acheteur dans les meilleures conditions.",
      },
    ],
  }),

  data: () => ({
    accueil: {},
    display: "",
    collapseOnScroll: true,
    collapse: false,
    videoOptions: {
      autoplay: true,
      controls: true,
      sources: [
        {
          src: "@/videos/presentation-tcso-1.mp4",
          type: "video/mp4",
        },
      ],
    },
  }),

  components: {
    AnnoncesFavorites,

    // VideoPlayer,
  },

  created() {
    this.$store.commit("SET_LAYOUT", "public-layout");

    this.$store
      .dispatch("bindAccueil")
      .then(() => {
        this.accueil = this.$store.getters.getAccueil;
      })
      .catch((err) => {
        console.log(err);
      });

    this.$store
      .dispatch("bindAnnonces")
      .then(() => {
        this.annonces = this.$store.getters.getAnnonces;
        console.log("annonces :", this.annonces);
      })
      .catch((err) => {
        console.log(err);
      });

    for (let item in this.annonces) {
      this.display += item.resume;
    }
  },
};
</script>

<style lang="sass" scoped>
.transpa
  opacity: 0.85

.popup
  position: fixed
  z-index: 10
  max-width: 350px
  width: 30%
  top: 100px
  right: 40px
</style>
