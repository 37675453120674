<template>
  <v-row class="justify-center">
    <v-col cols="12" md="6" class="text-center mx-auto">
      <h1>Login Firebase UI</h1>
      <div id="firebaseui-auth-container"></div>
      <!-- <div id="loader">Loading...</div> -->
    </v-col>
  </v-row>
</template>

<script>
import firebase from "firebase/app";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

export default {
  name: "Login",

  created() {
    this.$store.commit("SET_LAYOUT", "public-layout");
  },

  mounted() {
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: (authResult) => {
          firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
          const user = authResult.user;
          // user.getIdTokenResult()
          //     .then(idTokenresult => {
          //     user.admin = idTokenresult.claims.admin;
          //     this.$store.dispatch('setUser', user)
          //     console.log('admin : ', user.admin);
          //     })
          this.$store.dispatch("setUser", user);
          this.$router.push("/admin-home");
          return false;
        },
      },
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
    };
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    }
    ui.start("#firebaseui-auth-container", uiConfig);
  },
};
</script>

<style></style>
