<template>
  <div class="anchor container">
    <div v-if="loading">
      <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
    </div>

    <div v-if="!loading">
      <v-row class="justify-center">
        <!--    --------------------    IMAGES   --------------     -->

        <v-col cols="12" md="12">
          <!--    --------------------    TITRE   --------------     -->

          <v-card class="mb-3 pl-2 py-2 d-flex">
            <h1 class="headline">
              <span class=" font-weight-bold">{{
                annonce.type == "Vente murs" ? "A Vendre : " : "A Louer : "
              }}</span>
              {{ annonce.titre }} ({{ annonce.departement }})
            </h1>

            <v-spacer></v-spacer>

            <v-chip v-if="annonce.flash" color="error" small class="mr-3">
              {{ annonce.flash }}
            </v-chip>
          </v-card>
        </v-col>

        <!--    --------------------    DESCRIPTION DU BIEN   --------------     -->

        <v-col cols="12" md="12">
          <v-card class="mb-3 pl-2">
            <h2 class="headline font-weight-light">Description du bien</h2>
            <v-divider class="mb-3 mt-1"></v-divider>
            <div v-if="annonce.sous_titre" class="subtitle-1 py-3">
              {{ annonce.sous_titre }}
            </div>
            <span class="subtitle-1 font-weight-light"
              >Surface de {{ annonce.surface }} M² environ<br
            /></span>
            <span
              v-if="annonce.surface_details"
              class="subtitle-2 font-weight-light"
              >{{ annonce.surface_details }}<br
            /></span>

            <v-divider class="mb-3 mt-5"></v-divider>

            <div class="d-flex align-center pb-2">
              <span v-if="annonce.parking" class="subtitle-2">
                <v-icon class="mr-2">mdi-car-info</v-icon> Parking de
                {{ annonce.parking }} places
              </span>

              <span v-if="annonce.parking_details" class="subtitle-2 ml-2">{{
                annonce.parking_details
              }}</span>

              <span v-if="annonce.autre_service" class="subtitle-2">
                <v-icon class="ml-5 mr-2">mdi-plus-box-outline</v-icon
                >{{ annonce.autre_service }}
              </span>

              <v-spacer></v-spacer>

              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="annonce.plu"
                    class="subtitle-2"
                    :href="annonce.plu"
                    target="blank"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    Consulter le P.L.U <v-icon class="ml-2">mdi-link</v-icon>
                  </v-btn>
                </template>
                <span>Plan Local d'Urbanisme</span>
              </v-tooltip>
            </div>
          </v-card>

          <!--    --------------------    CONDITIONS FINANCIERES & JURIDIQUES   --------------     -->

          <v-card class="mb-3 pl-2">
            <h2 class="headline font-weight-light">
              Conditions financières & juridiques
            </h2>
            <v-divider class="mb-3 mt-1"></v-divider>
            <span v-if="annonce.prix" class="subtitle-1 font-weight-light"
              >Prix de vente : {{ annonce.prix }}€</span
            >
            <span
              v-if="annonce.prix_details"
              class="subtitle-1 font-weight-light"
            >
              {{ annonce.prix_details }}</span
            ><br v-if="annonce.prix" />

            <span v-if="annonce.loyer" class="subtitle-1 font-weight-light"
              >Loyer : {{ annonce.loyer }}€</span
            >
            <span
              v-if="annonce.loyer_details"
              class="subtitle-1 font-weight-light"
            >
              {{ annonce.loyer_details }}</span
            ><br v-if="annonce.loyer" />

            <span v-if="annonce.prov" class="subtitle-1 font-weight-light"
              >Prov s/ charges : {{ annonce.prov }}€</span
            >
            <span
              v-if="annonce.prov_details"
              class="subtitle-1 font-weight-light"
            >
              {{ annonce.prov_details }}</span
            ><br v-if="annonce.prov" />

            <span
              v-if="annonce.droit_au_bail"
              class="subtitle-1 font-weight-light"
              >Droit au bail: {{ annonce.droit_au_bail }}€</span
            >
            <span
              v-if="annonce.droit_au_bail_details"
              class="subtitle-1 font-weight-light"
            >
              {{ annonce.droit_au_bail_details }}</span
            ><br v-if="annonce.droit_au_bail" />

            <span
              v-if="annonce.fond_de_commerce"
              class="subtitle-1 font-weight-light"
              >Droit au bail: {{ annonce.fond_de_commerce }}€</span
            >
            <span
              v-if="annonce.fond_de_commerce_details"
              class="subtitle-1 font-weight-light"
            >
              {{ annonce.fond_de_commerce_details }}</span
            ><br v-if="annonce.fond_de_commerce" />

            <span v-if="annonce.bail" class="subtitle-2 font-weight-light"
              >Bail : {{ annonce.bail }}</span
            ><br v-if="annonce.bail" />

            <span
              v-if="annonce.taxe_fonciere_details"
              class="subtitle-2 font-weight-light"
              >Taxe foncière : {{ annonce.taxe_fonciere }}€</span
            >
            <span
              v-if="annonce.taxe_fonciere_details"
              class="subtitle-1 font-weight-light ml-1"
              >{{ annonce.taxe_fonciere_details }}</span
            ><br v-if="annonce.taxe_fonciere" />

            <v-divider class="mb-3 mt-5"></v-divider>

            <div class="font-italic d-flex justify-end mr-3">
              <p v-if="annonce.ratio_prix" class="">
                Soit : {{ annonce.ratio_prix }} €/M²/an
              </p>
              <v-spacer></v-spacer>
              <p v-if="annonce.honoraires" class=" font-weight-light">
                Honoraires à la charge du preneur : {{ annonce.honoraires }}
              </p>
            </div>
          </v-card>

          <div class="d-none d-print-block">
            <v-img
              alt="TCSO logo"
              width="30%"
              class="mx-auto"
              src="https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/main%2Ftest-tcso-bleu.png?alt=media&token=bd5cbfb6-437a-443b-92bf-f01c1177c731"
            ></v-img>
          </div>

          <!-- -------------    IMAGES    ---------------- -->

          <div class="page-break"></div>

          <v-row>
            <v-col cols="12" class="">
              <v-card class=" elevation-2 grey darken-4 py-5 justify-center">
                <ul class="justify-center mx-auto text-center">
                  <li
                    class=""
                    v-for="(image, index) in this.media"
                    :key="index"
                    style="display: inline-block"
                  >
                    <img
                      v-lazy="image.src || image.thumb"
                      style="height: 200px;margin-right: 10px;"
                      @click="openGallery(index)"
                    />
                    <p class="grey--text font-weight-thin mb-0">
                      {{ image.caption }}
                    </p>
                  </li>
                </ul>
                <LightBox
                  ref="lightbox"
                  :media="this.media"
                  :showLightBox="false"
                  :showThumbs="true"
                ></LightBox>
              </v-card>
            </v-col>
          </v-row>

          <!--  -------------   ENVIRONNEMENT   ---------------- -->

          <div class="page-break"></div>
          <v-card class="mb-3 pl-2">
            <h2 class="headline font-weight-light">
              Environnement et localisation
            </h2>

            <v-divider class="mb-3 mt-1"></v-divider>

            <!--    ---    GOOGLE MAP   --     -->
            <v-row>
              <v-col cols="12" md="3" class="mx-2 pa-2">
                <div v-if="annonce.map" class="iframe-container elevation-2">
                  <iframe
                    :src="annonce.map"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    style="border:0;"
                    allowfullscreen="true"
                    aria-hidden="false"
                  ></iframe>
                </div>
              </v-col>

              <v-divider
                class="mx-2 my-3 d-none d-md-block"
                vertical
              ></v-divider>

              <v-col cols="12" md="7" class=" pa-2">
                <div class=" align-center pb-2">
                  <div v-if="annonce.metro" class="subtitle-2">
                    <v-icon class="mr-2 mb-2">mdi-subway</v-icon>
                    {{ annonce.metro }}
                  </div>
                  <div v-if="annonce.bus" class="subtitle-2">
                    <v-icon class=" mr-2 mb-2">mdi-bus-stop</v-icon
                    >{{ annonce.bus }}
                  </div>
                </div>
                <div v-if="annonce.environnement">
                  <div class="subtitle-1">{{ annonce.environnement }}</div>
                </div>
              </v-col>
            </v-row>

            <v-divider class="mb-3 mt-1"></v-divider>
          </v-card>
        </v-col>
      </v-row>
      <div class="page-break"></div>
    </div>

    <!-- -------------- Boutons   ----------    -->

    <v-row class="d-print-none">
      <v-col class="d-flex justify-space-between">
        <v-btn class=" mr-3" text @click="print">Imprimer</v-btn>

        <v-btn
          v-if="annonce.pdf"
          class="mx-auto red white--text"
          small
          :href="annonce.pdf.url"
          ><v-icon small>mdi-file-pdf</v-icon>Téléchargez le PDF</v-btn
        >

        <v-btn class=" mr-3" text to="/annonces#anchor">Retour</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LightBox from "vue-image-lightbox";
require("vue-image-lightbox/dist/vue-image-lightbox.min.css");

export default {
  data: () => ({
    annonces: [],
    annonce: [],
    loading: true,
    dialog: false,
    media: [],
  }),
  metaInfo: () => ({
    title: "Details Annonce",
    meta: [
      {
        name: "description",
        content:
          "Consultez tous les détails de cette annonce de vente, de location de locaux commerciaux avec ou sans droit au bail ou droit d’entrée, de cession ou acquisition de murs commerciaux dans le sud-ouest de la france",
      },
    ],
  }),

  components: {
    LightBox,
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    },
    close() {
      this.dialog = false;
    },
    print() {
      window.print();
    },
  },

  async created() {
    this.$store.dispatch("noSnackbar");
    this.$store.commit("SET_LAYOUT", "public-layout");
    this.loading = true;

    await this.$store
      .dispatch("bindAnnonces")
      .then(() => {
        this.annonces = this.$store.getters.getAnnonces;
        console.log("annonces :", this.annonces);
      })
      .catch((err) => {
        console.log(err);
      });

    await this.$store
      .dispatch("getAnnonce", this.$route.params.annonce_id)
      .then(() => {
        this.loading = false;
        this.annonce = this.$store.getters.getAnnonce;
        let list = this.annonce.images;
        // console.log(list);
        list.forEach((item) => {
          this.media.push({
            src: item.url,
            thumb: item.url,
            caption: item.titre,
            ordre: item.ordre,
          });
        });
        // console.log("media : ", this.media);
      })
      .catch((err) => {
        console.log("media error : ", err);
      });
  },
};
</script>

<style lang="sass">
.iframe-container
  overflow: hidden
  // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
  padding-top: 100%
  position: relative
.iframe-container iframe
   border: 0
   left: 0
   position: absolute
   top: 0
   width: 100%
.vue-lb-container
  background-color: rgba(0, 0, 0, 0.92)

@media print
  .page-break
    page-break-after: always !important
</style>
