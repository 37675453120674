<template>
  <div class="anchor">
    <v-row
      v-if="accueil.bandeau_actif"
      class="justify-center text-center primary white--text"
    >
      <v-col cols="12">
        <h2 class="font-weight-thin title d-none d-md-block">
          {{ accueil.bandeau }}
        </h2>
        <h2 class="font-weight-thin subtitle-2 d-xs-block d-sm-block d-md-none">
          {{ accueil.bandeau }}
        </h2>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" md="8" sm="12" class="pt-0">
        <!-- ----------  Search Desktop ----------------------------- -->

        <v-app-bar
          :collapse="!collapse"
          min-width="350px"
          color="ternary"
          dark
          class="ml-5 mr-4 d-none d-sm-block"
        >
          <v-icon large @click="collapse = !collapse">mdi-magnify</v-icon>
          <h3 v-if="!collapse" class="ml-3 title" @click="collapse = !collapse">
            Chercher un bien
          </h3>

          <v-col v-if="collapse" class="d-flex align-center" cols="6" sm="3">
            <v-select
              :items="dpts"
              @change="selectType"
              v-model="dpt"
              label="Département"
              class="mt-5 pt-2 ml-10"
            ></v-select>
          </v-col>
          <div v-if="collapse" class="ml-5">et</div>
          <v-col v-if="collapse" class="d-flex align-center" cols="6" sm="3">
            <v-select
              :items="types"
              @change="selectType"
              v-model="type"
              label="Type de bien"
              class="mt-5 pt-2 ml-5"
            ></v-select>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            aria-label="Chercher un bien"
            v-model="collapse"
            @click="collapse = !collapse"
            color=""
            text
            icon
            ><v-icon v-if="!collapse">mdi-arrow-right</v-icon>
            <v-icon v-if="collapse">mdi-arrow-left</v-icon></v-btn
          >
        </v-app-bar>

        <!-- ----------  Search Mobile ----------------------------- -->

        <v-app-bar
          :collapse="!collapse"
          min-width="260px"
          color="ternary"
          dark
          class="ml-5 mr-4 d-block d-sm-none"
        >
          <v-icon large @click="collapse = !collapse">mdi-magnify</v-icon>
          <v-col v-if="collapse" class="d-flex align-center" cols="4">
            <v-select
              :items="dpts"
              @change="selectType"
              v-model="dpt"
              label="Département"
              class="mt-5 pt-2"
            ></v-select>
          </v-col>
          <div v-if="collapse" class="ml-1">et</div>
          <v-col v-if="collapse" class="d-flex align-center" cols="5">
            <v-select
              :items="types"
              @change="selectType"
              v-model="type"
              label="Type de bien"
              class="mt-5 pt-2"
            ></v-select>
          </v-col>
          <h2 v-if="!collapse" class="subtitle-1 ml-2">Chercher un bien</h2>

          <v-spacer></v-spacer>

          <v-btn
            aria-label="Chercher un bien"
            v-model="collapse"
            @click="collapse = !collapse"
            small
            text
            icon
            ><v-icon v-if="!collapse">mdi-arrow-right</v-icon>
            <v-icon v-if="collapse">mdi-arrow-left</v-icon></v-btn
          >
        </v-app-bar>

        <!-- ----------  ANNONCES ----------------------------- -->

        <v-row v-if="selectedAnnonces.length > 0" class="mx-2">
          <v-col
            v-for="annonce in selectedAnnonces"
            :key="annonce.id"
            cols="12"
          >
            <annonce-short :annonce="annonce"></annonce-short>
          </v-col>
        </v-row>

        <v-row v-else class="justify-center">
          <v-col class="justify-center">
            <v-card class="ml-5 mr-4 ">
              <v-card-title class="text-center"
                >Pas d'annonce dans cette catégorie</v-card-title
              >
              <v-card-text>
                <p>Nous pouvons toutefois interroger notre réseau</p>
                <v-btn>Contactez nous</v-btn></v-card-text
              >
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AnnonceShort from "@/components/annonces/AnnonceShort";

export default {
  metaInfo: () => ({
    title: "Nos Annonces",
    meta: [
      {
        name: "description",
        content:
          "Consultez nos annonces détaillées de vente, de location de locaux commerciaux avec ou sans droit au bail ou droit d’entrée, de cession ou acquisition de murs commerciaux dans le sud-ouest de la france",
      },
    ],
  }),

  data: () => ({
    annonces: [],
    annonce: "",
    selectedAnnonces: [],
    type: "Tous",
    dpt: "Tous",
    collapse: false,
    types: ["Tous", "Location", "Vente"],
    dpts: ["Tous", "09", "11", "31", "32", "34", "65", "66", "81", "82"],
  }),

  components: {
    AnnonceShort,
  },

  created() {
    this.$store.commit("SET_LAYOUT", "public-layout");
    this.$store.commit("SET_ANNONCE", null);
    this.annonces = this.$store.getters.getAnnonces;
    this.accueil = this.$store.getters.getAccueil;
    this.selectedAnnonces = this.annonces;
  },

  methods: {
    selectType() {
      // console.log("type selected : ", this.type);
      if (this.dpt == "Tous" && this.type == "Tous") {
        this.selectedAnnonces = this.annonces;
      } else if (this.dpt == "Tous" && this.type != "Tous") {
        this.selectedAnnonces = this.annonces.filter((annonce) => {
          // return annonce.departement == this.dpt;
          if (this.type == "Vente") {
            return annonce.type == "Vente murs";
          } else {
            return annonce.type != "Vente murs";
          }
        });
      } else if (this.dpt != "Tous" && this.type == "Tous") {
        this.selectedAnnonces = this.annonces.filter((annonce) => {
          return annonce.departement == this.dpt;
        });
      } else if (this.dpt != "Tous" && this.type != "Tous") {
        // console.log(this.type, this.dpt);
        this.selectedAnnonces = this.annonces.filter((annonce) => {
          if (this.type == "Vente") {
            return (
              annonce.type == "Vente murs" && annonce.departement == this.dpt
            );
          } else {
            return (
              annonce.type != "Vente murs" && annonce.departement == this.dpt
            );
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="sass"></style>
