<template>
  <div class="container">
    <v-row class="justify-center">
      <v-col cols="12" md="8" class="justify-center">
        <h1 class="text-center title">Qui sommes nous ?</h1>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="my-10">
      <v-col cols="12" md="3" class="">
        <v-img
          src="https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/qui%2FPORTRAIT2.jpg?alt=media&token=fb425365-1ee3-4763-93e8-e1b931bf6bb8"
        ></v-img>
      </v-col>
      <v-col cols="12" md="9" class="mt-2">
        <p>
          Après 20 années passées au sein du Groupe Carrefour dans les secteurs
          immobiliers et financiers, j’ai fait le choix de quitter cette grande
          entreprise pour une aventure entrepreneuriale.
        </p>
        <p>
          Mon parcours au sein de ce groupe a été particulièrement riche en
          expériences diverses. D’un poste à dominante financière et juridique,
          j’ai évolué dans le secteur de l’expansion immobilière au sein de la
          filiale proximité pour enfin accéder à un poste de directeur du
          contrôle de gestion, membre du comité de direction.
        </p>
        <p>
          J’ai ainsi eu la chance de travailler dans un secteur économique qui a
          connu une véritable transformation durant ces 20 dernières années
          (création de nouvelles enseignes et disparition d’autres, rachat de
          plus de 800 magasins concurrents et leur intégration dans le groupe…).
        </p>
        <p>
          Dans ce cadre j’ai évolué tant dans le monde des magasins intégrés
          avec ses process et sa grande force de frappe que dans celui
          passionnant de la franchise et ses entreprises individuelles avec ses
          aventures humaines chaque fois différentes.
        </p>
        <p>
          Ainsi j’ai vécu l’aventure palpitante de la création de magasins, de
          la page blanche jusqu’à l’ouverture. Autant d’étapes que j’ai chaque
          fois pu suivre avec un réel plaisir.
        </p>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="my-10">
      <v-col cols="12" md="9" class="justify-center">
        <p>
          C’est fort de cette expérience que j’ai décidé aujourd’hui de mettre
          tout mon savoir-faire et mon énergie afin qu’acheteurs et vendeurs,
          bailleurs et locataires puissent se rencontrer dans les meilleures
          conditions.
        </p>
        <p>
          Dénicher des emplacements, prospecter, identifier des potentiels,
          négocier et nouer des relations autant de compétences que je souhaite
          mettre aujourd’hui au bénéfice de ma clientèle.
        </p>
        <p>
          Passionné de course à pied avec une attirance pour les courses longues
          distances en semi autonomie mon maître mot est la persévérance.
        </p>
        <p>
          J’ai par ailleurs à cœur de toujours traiter mes dossiers avec
          intégrité et humanité.
        </p>
        <p>
          Aussi que vous soyez commerçant individuel ou grand groupe,
          propriétaire ou locataire je vous accompagnerai dans la réalisation de
          votre projet alors n’attendons plus et partageons ensemble un bout de
          chemin!
        </p>
      </v-col>
      <v-col cols="12" md="3" class="justify-center">
        <v-img
          src="https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/qui%2FMontcalm_2012.jpeg?alt=media&token=c9a47dd6-1dee-4fe5-85c7-5dbb9e669681"
        ></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  metaInfo: () => ({
    title: "Qui sommes nous",
    meta: [
      {
        name: "description",
        content:
          "Présentation de la société TCSO, de ses missions et de son dirigeant Nicolas Ferréol qui après 20 ans d’expérience dans l’immobilier et la finance a souhaité mettre son expérience au profit de ses clients",
      },
    ],
  }),

  data: () => ({}),
  created() {
    this.$store.commit("SET_LAYOUT", "public-layout");
  },
};
</script>

<style></style>
