<template>
  <v-app id="anchor">
    <!--  -------------   MENU PRINCIPAL    ------------     -->

    <v-app-bar app color="light" dark class="d-print-none">
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <v-btn href="/" text active-class="false">
          <v-img
            alt="TCSO Logo"
            class="shrink mr-2"
            contain
            src="https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/main%2Ftest-tcso-bleu.png?alt=media&token=bd5cbfb6-437a-443b-92bf-f01c1177c731"
            transition="scale-transition"
            width="40"
            height="40"
          />
          <v-toolbar-title>TCSO</v-toolbar-title>
        </v-btn>
      </div>

      <v-spacer></v-spacer>

      <div
        v-if="
          this.$store.getters.getUser &&
            this.$store.getters.getUser.admin === true
        "
      >
        <v-btn href="/admin-home" text>
          <span class="mr-2">Admin</span>
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </div>
      <!-- <div v-if="!this.$store.getters.getUser">
        <v-btn href="/login" text>
          <span class="mr-2">Login</span>
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </div> -->
    </v-app-bar>

    <!-- ------------   DRAWER    ---------------------- -->

    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list nav dense>
        <v-list-item-group active-class="teal--text text--accent-4">
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Accueil</v-list-item-title>
          </v-list-item>

          <v-list-item to="/annonces">
            <v-list-item-icon>
              <v-icon>mdi-text-box-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Annonces</v-list-item-title>
          </v-list-item>

          <v-list-item to="/informations">
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Informations</v-list-item-title>
          </v-list-item>

          <v-list-item to="/qui-sommes-nous">
            <v-list-item-icon>
              <v-icon>mdi-account-search-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Qui sommes nous?</v-list-item-title>
          </v-list-item>

          <v-list-item to="/contacts">
            <v-list-item-icon>
              <v-icon>mdi-at</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contacts</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
    <public-footer class="d-print-none"></public-footer>
  </v-app>
</template>

<script>
import PublicFooter from "@/layouts/Footer";
export default {
  data: () => ({
    drawer: false,
  }),
  components: {
    PublicFooter,
  },
};
</script>
