<template>
  <v-row class="justify-center mx-2 anchor">
    <v-col cols="12">
      <h1 class="text-center mb-5 title">
        Base de connaissances immobilières et conseils
      </h1>
    </v-col>

    <v-col cols="12">
      <iframe src="https://www.insee.fr/fr/statistiques/serie/001532540#Graphique" frameborder="0" height="400" width="100%"></iframe>
    </v-col>

      <v-col cols="12">
        <video controls src="https://drive.google.com/file/d/1_IjO4CCmDvxpkvLWIiPS33XfCsUEGCBr/view?usp=sharing">
        </video>
      </v-col>

    <v-col cols="12" md="3">
      <v-img
        class="mt-3"
        src="https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/informations%2Fsale-3701777_640.jpg?alt=media&token=bbbaee87-cdf8-447e-b064-2646837c7bb3"
      ></v-img>
    </v-col>

    <v-col cols="12" md="5">
      <p>
        Combien de fois j’ai entendu des commerçants souhaitant «céder leur
        affaire». Cette expression généraliste si elle traduit la volonté de
        vendre n’a aucun sens juridiquement parlant.
      </p>
      <p>
        En effet un commerçant c’est-à-dire un professionnel titulaire d’un bail
        commercial dispose de multiples possibilités s’il souhaite vendre.
      </p>
      <p>
        Ces différentes possibilités se traduiront par des règles financières,
        juridiques et fiscales différentes.
      </p>
      <p>
        Nous entendrons ici un commerçant établit dans le cadre d’une société
        (SARL, SAS…) c’est-à-dire une personne morale distincte de la personne
        physique du commerçant.
      </p>
      <p>
        Le commerçant dans ce contexte qui aurait décidé de «céder son affaire»
        peut à son gré décider de vendre son fonds de commerce, son seul droit
        au bail ou sa société.
      </p>
    </v-col>

    <v-expansion-panels focusable popout>
      <v-col cols="12" md="8">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>
              La vente d’un fonds de commerce:
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="mt-5">
              Il faut savoir que le droit Français n’a pas jugé utile de définir
              précisément le fonds de commerce, le législateur a seulement donné
              un certain nombre de critères permettant de préciser cette notion.
            </p>
            <p>
              Ainsi, le titre IV du livre 1er du code de commerce sur la vente
              et le nantissement des fonds de commerce énumère les éléments qui
              composent le fonds et pose certaines règles qui permettent de
              définir des principes sur les conditions d'existence et la nature
              du fonds de commerce.
            </p>
            <p>
              Le fonds de commerce est hétérogène, il est composé d'éléments
              disparates qui peuvent être aisément détachés du fonds et sont
              essentiellement périssables. La loi distingue les éléments
              incorporels des éléments corporels.
            </p>
            <v-col cols="12" md="8" class="justify-center">
              <v-img
                class="mx-auto"
                width="100%"
                src="https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/informations%2Ffdc.png?alt=media&token=092e95e8-8ea9-48bd-b112-3fb73811c0eb"
              ></v-img>
            </v-col>

            <p>
              Pour les <span class="strong">éléments incorporels</span> la loi
              fixe une liste non exhaustive comme :
            </p>
            <ul class="mb-5">
              <li>L’enseigne et le nom commercial</li>
              <li>La clientèle et l'achalandage</li>
              <li>
                Les brevets d'invention, les licences, les marques de fabrique
                et de commerce, les dessins et modèles industriels et
                généralement les droits de propriété industrielle, littéraire ou
                artistique qui y sont attachés
              </li>
            </ul>

            <p>
              Le droit au bail est un élément essentiel du fonds de commerce,
              nous y reviendrons un peu plus loin.
            </p>
            <p>
              Par ailleurs, un certain nombre de commerces nécessitent pour leur
              création, leur exploitation, des autorisations, des licences, des
              agréments, des cartes professionnelles, etc...
            </p>
            <p>
              Certaines de ces autorisations ont un caractère personnel, de
              sorte qu'elles ne peuvent être un élément du fonds. Dans d'autres
              cas, ces autorisations ont un caractère réel car, attribuées pour
              l'exploitation d'un fonds de commerce déterminé, leur acquisition
              est de plein droit pour l'acquéreur du fonds, devenant ainsi un
              élément du fonds (par ex les licences de débit de boisson).
            </p>
            <p>
              Enfin, lorsqu'il existe un droit de concession immobilière, qui
              constitue un droit réel, le concessionnaire peut donner son droit
              en location-gérance. Il doit donc être considéré comme un élément
              du fonds de commerce au même titre que le droit au bail.
            </p>
            <p>
              Concernant les éléments corporels il s’agit essentiellement du
              matériel et des marchandises. Le matériel comprend tous les
              meubles corporels qui permettent l'exploitation du fonds de
              commerce : meubles meublants, outillage, machines.
            </p>
            <p>
              Les marchandises (stocks) comprennent tous les meubles corporels
              destinés à être vendus, l'achat et la revente de ces meubles étant
              l'objet même de l'exploitation.
            </p>
            <p>
              Point particulier sur les contrats de travail, en cas de cession
              de fonds de commerce, ceux -ci sont obligatoirement repris par
              l’acquéreur, c’est une obligation. En outre les salariés
              bénéficient d’un droit de reprise du fonds de commerce. De ce
              droit découle une procédure bien particulière d’information des
              salariés à impérativement respecter.
            </p>
            <p>
              Céder un fonds de commerce oblige donc de céder à la même
              activité, un fonds de commerce de boulangerie-pâtisserie ne pourra
              être repris que par une boulangerie-pâtisserie. Le produit de la
              vente sera encaissé par la société propriétaire du fonds de
              commerce avec un fiscalité applicable.
            </p>
            <p>
              Le produit de la cession devra être séquestré pendant une période
              assez longue (entre 3 et 5 mois) le temps à l’administration
              fiscale et aux créanciers de présenter des oppositions.
            </p>
            <p>
              Le vente en elle-même peut être constatée par acte authentique
              (notaire) ou sous seing privé (avocat ou conseil) et elle doit
              répondre à un certain nombre d’impératifs juridiques.
            </p>
            <p>
              La valorisation d’un fonds de commerce répond en général à des
              règles d’évaluation en fonction du chiffre d’affaires. Un fonds de
              commerce dans telle ou telle activité sera évalué à x% de son
              chiffre d’affaires annuels. On peut ainsi assez rapidement se
              faire une idée de la justesse de la proposition.
            </p>
            <p>
              Mais cette évaluation doit tenir compte d’autres éléments
              essentiels comme par exemple le loyer. En effet si 2 fonds de
              commerce dans la même activité et réalisant le même chiffre
              d’affaires pourraient être évalués équitablement, si un des deux
              est débiteur d’un loyer 2x supérieur, l’acquéreur n’aura pas du
              tout réalisé la même affaire.
            </p>
            <p>
              En synthèse même si l’évaluation d’un fonds de commerce rentre
              dans la fourchette généralement retenue une analyse beaucoup plus
              fine s’imposera.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-col>
      <v-col cols="12" md="8">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>
              La cession de droit au bail
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="mt-5">
              Comme nous l’avons vu plus haut le droit au bail est un des
              éléments constitutifs du fonds de commerce. Un élément essentiel
              au même titre que la clientèle. Ceci nous impose de traiter un peu
              plus largement du bail commercial. Ce dernier contrat essentiel en
              matière commerciale est le contrat par lequel un bailleur
              (propriétaire) met à disposition un local commercial à un preneur
              (locataire commerçant). Ce contrat est régi par des dispositions
              d’ordre public (c’est-à-dire des notions desquelles on ne peut
              déroger) et des dispositions conventionnelles.
            </p>
            <p>
              C’est un acte essentiel et déterminant dans la vie d’un commerçant
              et doit faire l’objet de toutes les attentions car mal rédigé ou
              déséquilibré il peut avoir des conséquences extrêmement lourdes.
            </p>
            <p>
              Ce bail une fois signé entre les parties fait naître à l’égard du
              preneur un droit au bail c’est-à-dire le droit pour le preneur de
              jouir des locaux pour son activité. Ce droit est cessible mais sa
              cession est la plupart du temps encadrée par le bail commercial.
            </p>
            <p>
              En premier lieu un bail va définir la destination des locaux loués
              c’est-à-dire les activités possiblement exploitables dans le local
              considéré. On parlera ainsi de :
            </p>
            <ul class="mb-5">
              <li>
                Bail tous commerces pour lequel toutes les activités seront
                possibles
              </li>
              <li>
                Bail tous commerces avec exclusion, par exemple restauration ou
                commerces malodorants ou créant des nuisances olfactives
              </li>
              <li>
                Bail spécialisé, c’est-à-dire un bail ne permettant que
                l’activité exercée (bail pour un magasin d’optique, pour une
                alimentation générale) à l’exclusion de tout autres
              </li>
            </ul>
            <v-col cols="12" md="8" class="justify-center">
              <v-img
                class="mx-auto"
                width="100%"
                src="https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/informations%2Fbaux.png?alt=media&token=593dc7b3-3985-47a5-bf21-8424ddc34bd2"
              ></v-img>
            </v-col>
            <p>
              La destination est essentielle quand on souhaite céder son droit
              au bail puisqu’elle va déterminer vers qui il faut se tourner pour
              vendre dans les meilleures conditions.
            </p>
            <p>
              Il est évident qu’un bail spécialisé puisqu’il va «enfermer» le
              cédant à ne rechercher uniquement l’activité concernée.
            </p>
            <p>
              En cas de bail spécialisé seul le bailleur peut accepter la
              déspécialisation. Celle-ci s’accompagne en règle générale d’une
              augmentation de loyer et du versement d’une indemnité de
              déspécialisation. Ce n’est en rien une obligation et l’opération
              peut très bien s’effectuer gratuitement.
            </p>
            <p>
              Attention toutefois car la destination du bail commercial devra
              dans tous les cas être en adéquation avec le règlement de
              copropriété si le commerce est exercé en pied d’immeuble par
              exemple.
            </p>
            <p>
              Le bail commercial définira également la cession de droit au bail.
              En effet si un bail est tous commerces, sa cession pourra être
              encadrée par une cession non libre du droit au bail. Ceci signifie
              que pour toutes cessions le bailleur devra donner son
              autorisation.
            </p>
            <p>
              Il arrive parfois que des cessions soient libres, dans ces cas là
              le preneur pourra céder son droit au bail sans requérir
              l’autorisation du bailleur.
            </p>
            <p>
              Enfin dans d’autres cas la cession du droit au bail exigera juste
              la présence (ou la représentation) du bailleur à l’acte de
              cession.
            </p>
            <p>
              Dans tous les cas la cession de droit au bail devra à minima être
              signifiée au bailleur.
            </p>
            <p>
              Bien évidemment nous ne traitons ici que des clauses en relation
              avec le droit au bail mais toutes les clauses (durée, loyer,
              travaux, assurances, solidarité…) sont importantes et ne doivent
              pas être négligées d’où l’intérêt de toujours prendre un conseil
              (avocat ou notaire) avant de signer un bail commercial. Ceci a un
              coût bien évidemment mais évite toutes mauvaises surprises, un
              avocat ou un conseil juridique seront à même de tout vous
              expliquer dans les moindres détails, aucune zone d’ombre ne doit
              subsister au moment de la signature.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-col>
      <v-col cols="12" md="8">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h3>
              La cession de société
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="mt-5">
              Nous sommes ici dans un cas particulier où un vendeur décide de
              céder les parts ou les titres qu’il détient dans la société
              considérée. L’acquéreur achète donc la société avec tout ce qui la
              compose actif et passif.
            </p>
            <p>
              Ce mode de transmission est généralement utilisé pour des raisons
              fiscales, à titre d’exemple le repreneur ayant fait valoir ses
              droits à la retraite pourra avoir un intérêt à céder ses titres
              plutôt qu’un fonds de commerce.
            </p>
            <p>
              L’acte constatant la cession est un acte relativement complexe
              dans lequel les parties vont décider du prix provisoire, des
              modalités de fixation du prix définitif, de la garantie d’actif et
              de passif. L’acte de cession devra être rédigé par un
              professionnel.
            </p>
            <p>
              Au contraire de la cession du fonds de commerce et du droit au
              bail dans lesquels c’est la société qui encaissera le prix de la
              vente, c’est ici le propriétaire des parts (en général le
              commerçant) qui encaissera le prix de cession et qui sera
              fiscalisé à titre personnel.
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-col>
    </v-expansion-panels>
    <v-col cols="12" md="4">
      <v-img
        src="https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/informations%2Fkey-2323278_640.jpg?alt=media&token=380d8ec9-9a92-4fb7-96b8-19ba28700f9e"
      ></v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo: () => ({
    title: "Informations",
    meta: [
      {
        name: "description",
        content:
          "Combien de fois j’ai entendu des commerçants souhaitant «céder leur affaire». Cette expression généraliste si elle traduit la volonté de vendre n’a aucun sens juridiquement parlant. Le commerçant dans ce contexte qui aurait décidé de «céder son affaire» peut à son gré décider de vendre son fonds de commerce, son seul droit au bail ou sa société .",
      },
    ],
  }),
};
</script>

<style lang="sass" soped>
h3
  margin-top: 30px
  margin-bottom: 20px
</style>
