import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import Vue from "vue";
import VueLazyLoad from "vue-lazyload";
import App from "./App.vue";
import VueMeta from "vue-meta";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

const firebaseConfig = {
  apiKey: "AIzaSyCWfpuj6RrETqsTlzjBTQy_4Yvk0MMCGow",
  authDomain: "tcso-b3ea7.firebaseapp.com",
  databaseURL: "https://tcso-b3ea7.firebaseio.com",
  projectId: "tcso-b3ea7",
  storageBucket: "tcso-b3ea7.appspot.com",
  messagingSenderId: "959363696145",
  appId: "1:959363696145:web:50f6cd9535ff7f4853d3b0",
  measurementId: "G-B128LH1X1F",
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;

Vue.use(VueMeta);
Vue.use(VueLazyLoad);

new Vue({
  router,
  store,
  vuetify,

  render: (h) => h(App),
}).$mount("#app");
// document.addEventListener("DOMContentLoaded", function() {
//   root.$mount("#app");
// });
// .$mount("#app");

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    user.getIdTokenResult().then((idTokenresult) => {
      user.admin = idTokenresult.claims.admin;
      store.dispatch("setUser", user);
      // console.log("admin : ", user.admin);
    });
  } else {
    store.dispatch("setUser", null);
  }
});
