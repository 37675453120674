import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#0c7ca2',
                secondary: '#2bc0c4',
                ternary: '#263238',
                accent: '#c4d339',
                error: '#FF5252',
                info: '#2bc0c4',
                success: '#4CAF50',
                warning: '#FB8C00',
            },
            dark: {
                primary: '#1182a5',
                secondary: '#424242',
                accent: '#c4d339',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FB8C00',
            }
        },
      },
});
