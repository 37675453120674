import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/public/Home";
import PublicAnnonces from "@/views/public/PublicAnnonces";
import PublicAnnonce from "@/views/public/PublicAnnonce";
import Informations from "@/views/public/Informations";
import Qui from "@/views/public/Qui";
import Contacts from "@/views/public/Contacts";
import Mentions from "@/views/public/Mentions";
import Login from "@/components/auth/Login";
import AdminHome from "@/views/admin/AdminHome";
import AdminAnnonces from "@/views/admin/AdminAnnonces";
import AdminVueAnnonce from "@/views/admin/AdminVueAnnonce";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  // --------   PUBLIC    --------------------------------------

  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/annonces",
    name: "Annonces",
    component: PublicAnnonces,
  },
  {
    path: "/annonce/:annonce_id",
    name: "PublicAnnonce",
    component: PublicAnnonce,
  },
  {
    path: "/informations",
    name: "Informations",
    component: Informations,
  },
  {
    path: "/qui-sommes-nous",
    name: "Qui",
    component: Qui,
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: Contacts,
  },
  {
    path: "/mentions-legales",
    name: "Mentions",
    component: Mentions,
  },

  // --------   LOGIN   --------------------------------------

  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  // --------   PRIVE    --------------------------------------

  {
    path: "/admin-home",
    name: "AdminHome",
    component: AdminHome,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin-annonces",
    name: "AdminAnnonces",
    component: AdminAnnonces,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/admin-annonce/:annonce_id",
    name: "AdminVueAnnonce",
    component: AdminVueAnnonce,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
});

router.beforeEach((to, from, next) => {
  // console.log(`depuis ${from.path} et vers ${to.path}`);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.getUser == null) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
