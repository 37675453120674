<template>
<div class="admin-home">
  <v-row class="pt-0 justify-center">
    <v-col cols="12" class="pt-0 mx-5">
      <annonces-table></annonces-table>
    </v-col>
  </v-row>
</div>
</template>

<script>
import AnnoncesTable from '@/components/annonces/AnnoncesTable'

export default {
  components: {
    AnnoncesTable,
  },

  data: () => ({

  }),

  created() {
    this.$store.dispatch('noSnackbar');
    this.$store.commit('SET_LAYOUT', 'admin-layout');
  },
}
</script>

<style lang="sass">

</style>