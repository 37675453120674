<template>
  <v-app id="tcso" app data-server-rendered="true" data-view>
    <component v-bind:is="layout"></component>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" top>
      <span class="subtitle-1">{{ snackbar.message }}</span>
      <v-btn text @click="snackbar.show = false">fermer</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import AdminLayout from "@/layouts/AdminLayout";
import PublicLayout from "@/layouts/PublicLayout";
// import firebase from 'firebase/app'

export default {
  name: "App",

  data: () => ({}),

  metaInfo: () => ({
    // if no subcomponents specify a metaInfo.title, this title will be used
    lang: "fr",
    title: "TCSO",
    titleTemplate: "%s | TCSO",
    meta: [
      { name: "author", content: "Artisan Digital", vmid: "artisan digital" },
      { name: "copyright", content: "TCSO", vmid: "copyright" },
    ],
  }),

  components: {
    "admin-layout": AdminLayout,
    "public-layout": PublicLayout,
  },

  computed: {
    layout() {
      return this.$store.getters.getLayout;
    },
    ...mapState(["snackbar"]),
  },
};
</script>
<style scoped>

</style>