<template>
  <v-footer dark padless>
    <v-card class="flex" flat tile>
      <v-card-title class="secondary">
        <strong class="subheading"
          >Connectez vous sur nos réseaux sociaux!</strong
        >

        <v-spacer></v-spacer>

        <v-btn
          :href="icon.src"
          target="_blank"
          v-for="icon in icons"
          :key="icon.icon"
          class="mx-4"
          dark
          icon
        >
          <v-icon size="24px">{{ icon.icon }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="py-2 white--text text-center">
        {{ new Date().getFullYear() }} — <strong>TCSO</strong>

        <v-btn to="/mentions-legales" text small class="ml-5"
          >mentions légales</v-btn
        >
        <v-btn
          href="https://artisandigital.fr"
          target="_blank"
          text
          small
          class="ml-5"
          >Réalisé par Artisan Digital</v-btn
        >
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: [
      {
        icon: "mdi-facebook",
        src:
          "https://www.facebook.com/Transaction-et-Conseil-Sud-Ouest-Immobilier-Commercial-104829734615265/",
      },
      {
        icon: "mdi-linkedin",
        src: "https://www.linkedin.com/in/nicolas-ferreol-153431a4/",
      },
    ],
  }),
};
</script>

<style></style>
