<template>
  <v-row class="justify-center mx-1">
    <v-col cols="12" md="12" class="ternary">
      <v-toolbar flat color="">
        <v-toolbar-title>Mes Annonces</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn @click="newItem" color="accent" dark class="mb-2"
          >Nouvelle annonce</v-btn
        >
      </v-toolbar>

      <template>
        <v-simple-table fixed-header>
          <thead>
            <tr>
              <th class="text-left">Titre</th>
              <th class="text-left">Type</th>
              <th class="text-left">Surface</th>
              <th class="text-left">Prix</th>
              <th class="text-left">Loyer</th>
              <th class="text-left">Publiée</th>
              <th class="text-left">Favorite</th>
              <th class="text-left"></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="annonce in annonces" :key="annonce.id">
              <td>{{ annonce.titre }} ({{ annonce.departement }})</td>
              <td>{{ annonce.type }}</td>
              <td>{{ annonce.surface }} M²</td>
              <td>{{ annonce.prix || annonce.droit_au_bail }}€</td>
              <td>{{ annonce.loyer }} €</td>
              <td>
                <v-btn class="mr-5" icon>
                  <v-icon
                    v-if="annonce.online"
                    color="success"
                    @click="offline(annonce.id)"
                    >mdi-television</v-icon
                  >
                  <v-icon v-else color="error" @click="online(annonce.id)"
                    >mdi-television-off</v-icon
                  >
                </v-btn>
              </td>
              <td>
                <v-btn class="mr-5" icon>
                  <v-icon
                    v-if="annonce.favorite"
                    color="accent"
                    @click="noFavorite(annonce.id)"
                    >mdi-star</v-icon
                  >
                  <v-icon v-else color="" @click="favorite(annonce.id)"
                    >mdi-star</v-icon
                  >
                </v-btn>
              </td>
              <td>
                <v-btn class="mr-2" icon :to="`admin-annonce/${annonce.id}`">
                  <v-icon color="secondary">mdi-eye</v-icon>
                </v-btn>
                <v-icon
                  class="mr-2"
                  @click="editItem(annonce)"
                  color="secondary"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  class="mr-2"
                  @click="deleteItem(annonce.id)"
                  color="secondary"
                >
                  mdi-delete</v-icon
                >
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>

      <!-- ---------------            Formulaire Edition/Creation         ---------------- -->

      <v-dialog v-model="dialog" max-width="">
        <v-form>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span
              ><v-spacer></v-spacer
              ><v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-select
                      :items="types"
                      :item-text="editedItem.type"
                      :item-value="editedItem.type"
                      v-model="editedItem.type"
                      label="Type"
                      outlined
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="8" md="10">
                    <v-text-field
                      outlined
                      v-model="editedItem.titre"
                      label="Titre"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="2">
                    <v-text-field
                      outlined
                      v-model="editedItem.departement"
                      label="Département"
                    ></v-text-field>
                    <!-- <v-select
                      :items="departements"
                      :item-text="editedItem.departement"
                      :item-value="editedItem.departement"
                      v-model="editedItem.departement"
                      label="Département"
                      outlined
                    ></v-select> -->
                  </v-col>

                  <v-col cols="12">
                    <p class="title" v-if="editedItem.type">
                      <span>{{
                        editedItem.type == "Location"
                          ? "A Louer : "
                          : "A Vendre : "
                      }}</span>
                      <span
                        >{{ editedItem.titre }} ({{
                          editedItem.departement
                        }})</span
                      >
                      <span></span>
                    </p>
                  </v-col>
                  <!-- CANCEL/SAVE -->
                  <v-col cols="12" class="justify-right">
                    <div class="float-right">
                      <v-btn color="secondary" text @click="close"
                        >Cancel</v-btn
                      >
                      <v-btn color="secondary" text @click="save">Save</v-btn>
                    </div>
                  </v-col>

                  <!--  -------------   DESCRIPTION     -----------------  -->

                  <v-row v-if="editedItem.id">
                    <v-col cols="12" class="primary--text">
                      <div class="mb-0 pb-0 title">Description du bien</div>
                      <v-divider class="my-2 primary"></v-divider>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        clear-icon="mdi-close"
                        rows="8"
                        outlined
                        v-model="editedItem.sous_titre"
                        label="Description"
                      ></v-textarea>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="4"
                      md="12"
                      class="d-flex justify-center"
                    >
                      <v-text-field
                        v-model="editedItem.flash"
                        label="Message flash"
                        outlined
                        background-color="error"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        outlined
                        v-model="editedItem.surface"
                        label="Surface"
                        suffix="M²"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="8" md="9">
                      <v-text-field
                        outlined
                        v-model="editedItem.surface_details"
                        label="Détails surface"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        prepend-icon="mdi-car-info"
                        outlined
                        v-model="editedItem.parking"
                        label="Parking"
                        suffix="Places"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="8" md="9">
                      <v-text-field
                        outlined
                        v-model="editedItem.parking_details"
                        label="Détails parking"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        outlined
                        v-model="editedItem.autre_service"
                        label="Autres services"
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-if="editedItem.type == 'Vente murs'"
                    >
                      <v-text-field
                        outlined
                        v-model="editedItem.plu"
                        label="Lien PLU"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" class="pr-10">
                      <div class="float-right mr-10">
                        <v-btn
                          class="mr-5 mt-2"
                          icon
                          small
                          href="https://www.google.fr/maps/@43.5418123,1.0681149,7z"
                          target="_blank"
                          >Ouvrir Google Street<v-icon
                            class="ml-2"
                            color="primary"
                            >mdi-map</v-icon
                          ></v-btn
                        >
                      </div>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        prepend-icon="mdi-map-marker"
                        outlined
                        v-model="editedItem.map"
                        label="Lien Map Google"
                      ></v-text-field>
                    </v-col>

                    <!-- CANCEL/SAVE -->
                    <v-col cols="12" class="justify-right">
                      <div class="float-right">
                        <v-btn color="secondary" text @click="close"
                          >Cancel</v-btn
                        >
                        <v-btn color="secondary" text @click="save">Save</v-btn>
                      </div>
                    </v-col>

                    <!--  -------------------   CONDITIONS FINANCIERES  --------------------------- -->

                    <v-col cols="12" class="primary--text">
                      <div class="mb-0 pb-0 title">
                        Conditions financières et juridiques
                      </div>
                      <v-divider class="my-2 primary"></v-divider>
                    </v-col>

                    <v-col
                      v-if="
                        editedItem.type == 'Vente murs' ||
                          editedItem.type == 'Vente fonds' ||
                          editedItem.type == 'Location avec DAB'
                      "
                      cols="12"
                      sm="4"
                      md="3"
                    >
                      <v-text-field
                        outlined
                        suffix="€"
                        v-model="editedItem.prix"
                        label="Prix de vente"
                        hint="Prix de vente"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="
                        editedItem.type == 'Vente murs' ||
                          editedItem.type == 'Vente fonds' ||
                          editedItem.type == 'Location avec DAB'
                      "
                      cols="12"
                      sm="8"
                      md="9"
                    >
                      <v-text-field
                        outlined
                        v-model="editedItem.prix_details"
                        label="Infos prix"
                        hint="élément venant à la suite du prix de vente"
                        persistent-hint
                      ></v-text-field>
                    </v-col>

                    <v-col
                      v-if="editedItem.type == 'Vente murs'"
                      cols="12"
                      sm="4"
                      md="3"
                    >
                      <v-text-field
                        outlined
                        suffix="€"
                        v-model="editedItem.charges_copro"
                        label="Charges de copropriété"
                        hint="Charges de copropriété"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="editedItem.type == 'Vente murs'"
                      cols="12"
                      sm="8"
                      md="9"
                    >
                      <v-text-field
                        outlined
                        v-model="editedItem.charges_copro_details"
                        label="Infos charges de copro"
                        hint="élément venant à la suite des charges de copro"
                        persistent-hint
                      ></v-text-field>
                    </v-col>

                    <v-col
                      v-if="
                        editedItem.type == 'Location' ||
                          editedItem.type == 'Vente fonds' ||
                          editedItem.type == 'Location avec DAB'
                      "
                      cols="12"
                      sm="4"
                      md="3"
                    >
                      <v-text-field
                        outlined
                        suffix="€"
                        v-model="editedItem.loyer"
                        label="Loyer"
                        hint="Prix du loyer"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="
                        editedItem.type == 'Location' ||
                          editedItem.type == 'Vente fonds' ||
                          editedItem.type == 'Location avec DAB'
                      "
                      cols="12"
                      sm="8"
                      md="9"
                    >
                      <v-text-field
                        outlined
                        v-model="editedItem.loyer_details"
                        label="Infos loyer"
                        hint="élément venant à la suite du prix du loyer"
                        persistent-hint
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        outlined
                        suffix="€/M²/an"
                        v-model="editedItem.ratio_prix"
                        label=" Prix en €/M²/an"
                        hint="Prix unitaire"
                        persistent-hint
                      ></v-text-field>
                    </v-col>

                    <v-col
                      v-if="
                        editedItem.type == 'Location' ||
                          editedItem.type == 'Vente fonds' ||
                          editedItem.type == 'Location avec DAB'
                      "
                      cols="12"
                      sm="4"
                      md="3"
                    >
                      <v-text-field
                        outlined
                        suffix="€"
                        v-model="editedItem.prov"
                        label="Prov s/ ch"
                        hint="Provisions sur charges"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="
                        editedItem.type == 'Location' ||
                          editedItem.type == 'Vente fonds' ||
                          editedItem.type == 'Location avec DAB'
                      "
                      cols="12"
                      sm="8"
                      md="9"
                    >
                      <v-text-field
                        outlined
                        v-model="editedItem.prov_details"
                        label="Infos Prov s/ ch"
                        hint="élément venant à la suite des provisions"
                        persistent-hint
                      ></v-text-field>
                    </v-col>

                    <v-col
                      v-if="editedItem.type == 'Location avec DAB'"
                      cols="12"
                      sm="4"
                      md="3"
                    >
                      <v-text-field
                        outlined
                        suffix="€"
                        v-model="editedItem.droit_au_bail"
                        label="Droit au bail"
                        hint="Prix de vente du droit au bail"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="editedItem.type == 'Location avec DAB'"
                      cols="12"
                      sm="8"
                      md="9"
                    >
                      <v-text-field
                        outlined
                        v-model="editedItem.droit_au_bail_details"
                        label="Infos droit au bail"
                        hint="élément venant à la suite du prix du droit au bail"
                        persistent-hint
                      ></v-text-field>
                    </v-col>

                    <v-col
                      v-if="editedItem.type == 'Vente fonds'"
                      cols="12"
                      sm="4"
                      md="3"
                    >
                      <v-text-field
                        outlined
                        suffix="€"
                        v-model="editedItem.fond_de_commerce"
                        label="Fonds de commerce"
                        hint="Prix de vente du fond de commerce"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col
                      v-if="editedItem.type == 'Vente fonds'"
                      cols="12"
                      sm="8"
                      md="9"
                    >
                      <v-text-field
                        outlined
                        v-model="editedItem.fond_de_commerce_details"
                        label="Infos fonds de commerce"
                        hint="élément venant à la suite du prix du fond"
                        persistent-hint
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        outlined
                        suffix="€"
                        v-model="editedItem.taxe_fonciere"
                        label="Taxe foncière"
                        hint="Taxe Foncière"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="8" md="9">
                      <v-text-field
                        outlined
                        v-model="editedItem.taxe_fonciere_details"
                        label="Infos Taxe Foncière"
                        hint="élément venant à la suite de la taxe foncière"
                        persistent-hint
                      ></v-text-field>
                    </v-col>

                    <v-col
                      v-if="editedItem.type == 'Location'"
                      cols="12"
                      sm="12"
                      md="6"
                    >
                      <v-text-field
                        outlined
                        v-model="editedItem.bail"
                        label="Bail"
                        hint="Bail"
                        persistent-hint
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        outlined
                        suffix=""
                        v-model="editedItem.honoraires"
                        label="Honoraires"
                        hint="Honoraires"
                        persistent-hint
                      ></v-text-field>
                    </v-col>

                    <!-- CANCEL/SAVE -->

                    <v-col cols="12" class="justify-right">
                      <div class="float-right">
                        <v-btn color="secondary" text @click="close"
                          >Cancel</v-btn
                        >
                        <v-btn color="secondary" text @click="save">Save</v-btn>
                      </div>
                    </v-col>

                    <!--  -------------------   ENVIRONNEMENT  --------------------------- -->

                    <v-col cols="12" class="primary--text">
                      <div class="mb-0 pb-0 title">Environnement</div>
                      <v-divider class="my-2 primary"></v-divider>
                    </v-col>

                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        outlined
                        v-model="editedItem.metro"
                        label="Métro"
                        hint="Station de métro proche"
                        persistent-hint
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        outlined
                        v-model="editedItem.bus"
                        label="Bus"
                        hint="Lignes de bus proches"
                        persistent-hint
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        clearable
                        clear-icon="mdi-close"
                        rows="2"
                        outlined
                        v-model="editedItem.environnement"
                        label="Environnement"
                        hint="L'environnement"
                        persistent-hint
                      ></v-textarea>
                    </v-col>

                    <!-- CANCEL/SAVE -->

                    <v-col cols="12" class="justify-right">
                      <div class="float-right">
                        <v-btn color="secondary" text @click="close"
                          >Cancel</v-btn
                        >
                        <v-btn color="secondary" text @click="save">Save</v-btn>
                      </div>
                    </v-col>

                    <!--        PDF                -->

                    <v-col cols="12" class="primary--text">
                      <div class="mb-0 pb-0 title">Fiche PDF</div>
                      <v-divider class="my-2 primary"></v-divider>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-row>
                        <v-file-input
                          v-model="editedItem.pdf"
                          type="file"
                          accept=".pdf"
                          placeholder="Choisir un pdf sur votre ordinateur"
                          prepend-icon="mdi-file-pdf"
                          label="PDF"
                          outlined
                          show-size
                        ></v-file-input>

                        <v-progress-circular
                          class="ml-3 mt-2 success--text"
                          size="40"
                          v-if="this.progress != 0 && this.progress != 100"
                          :value="this.progress"
                        ></v-progress-circular>

                        <v-btn
                          v-if="editedItem.pdf"
                          color="primary"
                          :loading="this.$store.getters.getLoading"
                          class="ml-3 mt-5"
                          @click="uploadPDF()"
                          >Upload</v-btn
                        >
                      </v-row>
                    </v-col>

                    <v-col v-if="editedItem.pdf" cols="12">
                      <v-row>
                        <v-chip class="ml-5">
                          {{ editedItem.pdf.nom }}
                        </v-chip>
                        <v-btn
                          small
                          class="error ml-5"
                          @click="deletePDF(editedItem.id, editedItem.pdf.nom)"
                          >X</v-btn
                        >
                      </v-row>
                    </v-col>

                    <!--        DATE MISE EN LIGNE                -->

                    <v-col cols="12" class="primary--text">
                      <div class="mb-0 pb-0 title">Date de mise en ligne</div>
                      <v-divider class="my-2 primary"></v-divider>
                    </v-col>
                    <v-row class="justify-center">
                      <!-- <v-col
                        cols="12"
                        class="mx-auto justify-center text-center"
                      >
                        <v-date-picker
                          v-model="editedItem.date"
                          type="month"
                          locale="fr"
                        ></v-date-picker>
                      </v-col> -->

                      <v-col cols="11" sm="5">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          :return-value.sync="editedItem.date"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="editedItem.date"
                              label="Selectionner le mois de mise en ligne"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.date"
                            type="month"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menu.save(editedItem.date)"
                              >OK</v-btn
                            >
                          </v-date-picker>
                        </v-menu>
                      </v-col>

                      <!-- CANCEL/SAVE -->

                      <v-col cols="12" class="justify-right">
                        <div class="float-right">
                          <v-btn color="secondary" text @click="close"
                            >Cancel</v-btn
                          >
                          <v-btn color="secondary" text @click="save"
                            >Save</v-btn
                          >
                        </div>
                      </v-col>
                    </v-row>

                    <!--    -------------    IMAGES         -----------       -->

                    <v-col cols="12" class="primary--text">
                      <div class="mb-0 pb-0 title">Images</div>
                      <v-divider class="my-2 primary"></v-divider>
                    </v-col>

                    <v-col
                      cols="4"
                      v-for="image in editedItem.images"
                      :key="image.index"
                      class="text-center"
                    >
                      <v-img
                        :src="image.url"
                        class="ma-2"
                        alt=""
                        max-width="300px"
                      >
                      </v-img>
                      <div class="mb-2">{{ image.nom }}</div>
                      <v-text-field
                        outlined
                        v-model="image.titre"
                        label="Titre"
                      >
                      </v-text-field>
                      <v-text-field
                        outlined
                        v-model="image.ordre"
                        label="Ordre d'affichage"
                      >
                      </v-text-field>
                      <v-btn
                        small
                        class="error mx-auto"
                        @click="deleteImage(image)"
                        >X</v-btn
                      >
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-row>
                        <v-file-input
                          v-model="editedItem.image"
                          accept="image/png, image/jpeg"
                          placeholder="Choisir une image sur votre ordinateur"
                          prepend-icon="mdi-camera"
                          label="Image"
                          outlined
                          show-size
                        ></v-file-input>

                        <v-progress-circular
                          class="ml-3 mt-2 success--text"
                          size="40"
                          v-if="this.progress != 0 && this.progress != 100"
                          :value="this.progress"
                        ></v-progress-circular>

                        <v-btn
                          v-if="editedItem.image"
                          color="primary"
                          :loading="this.$store.getters.getLoading"
                          class="ml-3 mt-5"
                          @click="uploadImages()"
                          >Upload</v-btn
                        >
                      </v-row>
                    </v-col>

                    <!-- CANCEL/SAVE -->

                    <v-col cols="12" class="justify-right">
                      <div class="float-right">
                        <v-btn color="secondary" text @click="close"
                          >Cancel</v-btn
                        >
                        <v-btn color="secondary" text @click="save">Save</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer
              ><v-btn class="mr-10" icon @click="close"
                >Fermer<v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!-- ---------------       FIN Formulaire Edition/Creation         ---------------- -->
    </v-col>
  </v-row>
</template>

<script>
import firebase from "firebase/app";
import "firebase/storage";

export default {
  components: {},
  data: () => ({
    dialog: false,
    menu: false,
    types: ["Location", "Location avec DAB", "Vente fonds", "Vente murs"],
    departements: ["09", "11", "31", "32", "34", "65", "66", "81", "82"],
    annonces: [],
    editedIndex: -1,
    payload: null,
    uploadedImage: [],
    progress: 0.0,
    // dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),

    editedItem: {
      id: "",
      titre: "",
      sous_titre: "",
      departement: "",
      map: "",
      type: "",
      prix: 0,
      prix_details: "",
      loyer: "",
      loyer_details: "",
      ratio_prix: "",
      prov: "",
      prov_details: "",
      taxe_fon: "",
      taxe_fon_details: "",
      location: 0,
      droit_au_bail: 0,
      droit_au_bail_details: "",
      fond_de_commerce: 0,
      fond_de_commerce_details: "",
      bail: "",
      metro: "",
      bus: "",
      environnement: "",
      date: "",
      images: [],
      image: null,
      pdf: null,
    },

    defaultItem: {
      id: "",
      titre: "",
      sous_titre: "",
      departement: "",
      map: "",
      type: "",
      prix: 0,
      prix_details: "",
      loyer: "",
      loyer_details: "",
      ratio_prix: "",
      prov: "",
      prov_details: "",
      taxe_fon: "",
      taxe_fon_details: "",
      location: 0,
      droit_au_bail: 0,
      droit_au_bail_details: "",
      fond_de_commerce: 0,
      fond_de_commerce_details: "",
      bail: "",
      metro: "",
      bus: "",
      environnement: "",
      date: new Date().toISOString().substr(0, 7),
      images: [],
      pdf: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nouvelle annonce" : "Modifier annonce";
    },
    // computedDateFormatted() {
    //   return this.formatDate(this.date);
    // },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    // date() {
    //   this.dateFormatted = this.formatDate(this.date);
    // },
  },

  created() {
    this.$store.dispatch("noLoading");
    this.$store
      .dispatch("bindAnnonces")
      .then(() => {
        this.annonces = this.$store.getters.getAnnonces;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    // formatDate(date) {
    //   if (!date) return null;

    //   const [year, month, day] = date.split("-");
    //   return `${month}/${day}/${year}`;
    // },

    // parseDate(date) {
    //   if (!date) return null;

    //   const [month, day, year] = date.split("/");
    //   return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    // },

    uploadImages() {
      // console.log("fichier upload : ", this.editedItem.image);
      this.$store.dispatch("setLoading");
      const i = this.editedItem.image;
      const st = firebase.storage();
      const storageAnnonces = st
        .ref()
        .child("annonces/" + this.editedItem.id + "/" + i.name);
      const uploadTask = storageAnnonces.put(i);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          this.progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log("Upload is " + this.progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING:
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              let imageObjet = {
                url: downloadURL,
                titre: "image titre",
                ordre: "",
                nom: i.name,
              };
              this.annonce.images.push(imageObjet);
              delete this.annonce.image;
              // console.log("new image! : ", this.annonce);
              this.$store.dispatch("noLoading");
              this.$store.dispatch("addImage", this.annonce);
            })
            .catch((e) => {
              console.log("erreur downloadURL", e);
            });
        }
      );
    },

    deleteImage(image) {
      // console.log("image :", image);
      const sto = firebase.storage();
      const imageAnnonceRef = sto
        .ref()
        .child("annonces/" + this.editedItem.id + "/" + image.nom);
      imageAnnonceRef
        .delete()
        .then(() => {
          this.$store.dispatch("deleteImage", {
            id: this.editedItem.id,
            nom: image.nom,
          });
          console.log("image supprimée du storage avec succès");
          this.dialog = false;
          this.$store.dispatch("setSnackbar", {
            color: "success",
            message: "L'image a bien été supprimée de votre annonce",
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    uploadPDF() {
      console.log("upload PDF");
      this.$store.dispatch("setLoading");
      const pdf = this.editedItem.pdf;
      const st = firebase.storage();
      const storagePDF = st
        .ref()
        .child("pdf/" + this.editedItem.id + "/" + pdf.name);
      const uploadTask = storagePDF.put(pdf);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          this.progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log("Upload is " + this.progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING:
              console.log("Upload is running");
              break;
          }
        },
        (e) => {
          console.log("Erreur upload PDF", e);
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              let pdfObject = {
                url: downloadURL,
                nom: pdf.name,
              };
              this.annonce.pdf = pdfObject;

              // console.log("new image! : ", this.annonce);
              this.$store.dispatch("noLoading");
              this.$store.dispatch("addPDF", this.annonce);
            })
            .catch((e) => {
              console.log("erreur downloadURL", e);
            });
        }
      );
    },

    deletePDF(id, nom) {
      console.log(id, nom);
      const sto = firebase.storage();
      const pdfAnnonceRef = sto.ref().child("pdf/" + id + "/" + nom);
      pdfAnnonceRef
        .delete()
        .then(() => {
          this.$store.dispatch("deletePDF", id);
          console.log("pdf supprimé du storage avec succès");
          this.dialog = false;
          this.$store.dispatch("setSnackbar", {
            color: "success",
            message: "Le pdf a bien été supprimé de votre annonce",
          });
        })
        .catch((e) => {
          console.log("erreur suppression de PDF", e);
        });
    },

    favorite(id) {
      confirm("Etes vous sur de vouloir publier cette annonce ?") &&
        this.$store.dispatch("favorite", id);
    },

    noFavorite(id) {
      confirm("Etes vous sur de vouloir dépublier cette annonce ?") &&
        this.$store.dispatch("noFavorite", id);
    },

    online(id) {
      this.$store.dispatch("online", id);
    },

    offline(id) {
      this.$store.dispatch("offline", id);
    },

    editItem(annonce) {
      this.editedIndex = this.annonces.indexOf(annonce);
      this.editedItem = Object.assign({}, annonce);
      this.editedItem.id = annonce.id;
      this.$store.commit("SET_ANNONCE", this.editedItem);
      this.annonce = this.$store.getters.getAnnonce;
      // console.log("annonce éditée : ", this.editedItem);
      this.dialog = true;
    },

    newItem() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },

    deleteItem(id) {
      // console.log("id envoyé :", id);
      confirm("Etes vous sur de vouloir effacer cette annonce ?") &&
        this.$store
          .dispatch("deleteAnnonce", id)

          .then(() => {
            console.log("annonce supprimée avec succès !");
          })
          .catch((e) => {
            console.log("erreur suppression annonce :", e);
          });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      console.log(this.editedItem);
      this.$store.dispatch("setLoading");
      if (this.editedItem.id != "") {
        this.$store
          .dispatch("saveAnnonce", this.editedItem)
          .then(() => {
            this.$store.getters.getAnnonces;
            this.$store.dispatch("noLoading");
            this.$store.dispatch("setSnackbar", {
              color: "success",
              message: "Votre annonce a été mise à jour avec succès !",
            });
            this.close();
          })
          .catch((e) => {
            console.log("erreur dispatch saveAnnonce", e);
          });
      } else {
        this.$store
          .dispatch("newAnnonce", this.editedItem)
          .then(() => {
            this.$store.getters.getAnnonces;
            this.$store.dispatch("noLoading");
            this.close();
          })
          .catch((e) => {
            console.log("erreur dispatch newAnnonce", e);
          });
      }
    },
  },
};
</script>

<style lang="sass"></style>
