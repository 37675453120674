<template>
  <div class="admin-home">
    <div class="admin-home">
      <v-row class="d-flex mb-10" justify="space-around">
        <v-col cols="12" md="12">
          <v-card class="ternary pb-5">
            <v-card-title>
              <v-row class="">
                <v-col cols="12" md="12" class="pt-0 mt-0">
                  <h3 class="my-0 headline grey--text darken-4">
                    <v-icon class="mr-3" color="accent">mdi-cog</v-icon>Modifier
                    Page d'accueil
                  </h3>
                </v-col>
              </v-row>
            </v-card-title>

            <v-card class=" pb-5 ma-3 mb-3 pa-3" width="">
              <p>Texte du bandeau</p>
              <v-text-field
                outlined
                v-model="accueil.bandeau"
                label="Texte bandeau"
                required
              ></v-text-field>
              <v-row>
                <v-switch
                  class="mt-0 ml-5"
                  color="accent"
                  label="Activer"
                  v-model="accueil.bandeau_actif"
                ></v-switch>
              </v-row>
            </v-card>

            <v-card class=" pb-5 ma-3 pa-3" width="">
              <p>Sous-titre</p>
              <v-text-field
                outlined
                v-model="accueil.sous_titre"
                label="Sous titre"
              ></v-text-field>
            </v-card>

            <v-card class=" pb-5 ma-3 pa-3" width="">
              <p>Transactions réalisées</p>

              <v-data-table
                :headers="headers"
                :items="this.computeAccueil.transactions"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat color="white">
                    <v-toolbar-title>Mes Transactions</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>

                    <v-dialog v-model="dialog" max-width="800px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                          >Nouvelle Transaction</v-btn
                        >
                      </template>

                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-text-field
                                  v-model="editedItem.texte"
                                  label="Transaction"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="close"
                            >Cancel</v-btn
                          >
                          <v-btn color="blue darken-1" text @click="save"
                            >Save</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                  <!-- <v-icon color="primary" class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon> -->
                  <v-icon color="primary" @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card>

            <v-card-actions>
              <v-col cols="12" class="">
                <v-btn
                  :loading="this.$store.getters.getLoading"
                  color="accent"
                  class="mr-10 float-right"
                  @click="submitAccueil(accueil)"
                  small
                >
                  Enregistrer
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="justify-center my-5">
        <v-col>
          <div class="justify-center text-center">
            <QRCanvas :options="options"></QRCanvas>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-divider></v-divider>
    <v-row class="justify-center text-center">
      <v-col clos="12" md="4">
        <h3 class="my-3 title grey--text darken-4">
          Nommer un nouvel Admin TCSO
        </h3>
        <v-form>
          <v-text-field
            v-model="email"
            label="E-mail"
            required
            outlined
          ></v-text-field>
          <v-btn small color="error" class="mr-4" @click="addAdminRole">
            Nommer Admin
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";
import { QRCanvas } from "qrcanvas-vue";

export default {
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Transaction",
        align: "start",
        sortable: false,
        value: "texte",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    email: "",
    accueil: [],
    options: {
      cellSize: 8,
      data: "https://tcso.fr",
    },
  }),
  components: {
    QRCanvas,
  },

  async created() {
    this.$store.dispatch("noSnackbar");

    this.$store.commit("SET_LAYOUT", "admin-layout");
    await this.$store
      .dispatch("bindAccueil")
      .then(() => {
        this.accueil = this.$store.getters.getAccueil;
      })
      .catch((err) => {
        console.log(err);
      });

    const image = new Image();
    image.src =
      "https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/main%2Flogo-120-blanc-bg.png?alt=media&token=49af13dc-f3fd-4e5d-9d58-3464c58a8377";
    image.onload = () => {
      this.options = Object.assign({}, this.options, {
        logo: {
          image,
        },
      });
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nouvelle Transaction"
        : "Modifier Transaction";
    },
    computeAccueil() {
      return this.$store.getters.getAccueil;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    // editItem(item) {
    //   this.editedIndex = this.accueil.transactions.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialog = true;
    // },

    deleteItem(item) {
      this.$store.dispatch("deleteTransaction", item).then(() => {
        this.$store.getters.getAccueil;
        this.$store.dispatch("noLoading");
        this.$store.dispatch("setSnackbar", {
          color: "success",
          message: "Votre Transaction a été supprimée avec succès !",
        });
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        this.$store
          .dispatch("saveTransaction", this.editedItem)
          .then(() => {
            this.$store.getters.getAccueil;
            this.$store.dispatch("noLoading");
            this.$store.dispatch("setSnackbar", {
              color: "success",
              message: "Votre Transaction a été mise à jour avec succès !",
            });
            this.close();
          })
          .catch((e) => {
            console.log("erreur dispatch saveAnnonce", e);
          });
      } else {
        this.$store
          .dispatch("newTransaction", this.editedItem)
          .then(() => {
            this.$store.getters.getAccueil;
            this.$store.dispatch("noLoading");
            this.close();
          })
          .catch((e) => {
            console.log("erreur dispatch newAnnonce", e);
          });
      }
      this.close();
    },

    addAdminRole() {
      console.log(this.email);
      const addAdminRole = firebase.functions().httpsCallable("addAdminRole");
      addAdminRole({ email: this.email }).then((result) => {
        console.log(result);
      });
    },
    async submitAccueil() {
      this.$store.dispatch("setLoading");
      console.log("accueil :", this.accueil);
      await this.$store.dispatch("updateAccueil", this.accueil).then(() => {
        this.$store.dispatch("noLoading");
        this.$store.dispatch("setSnackbar", {
          color: "success",
          message: "La page d'accueil a été mise à jour avec succès !",
        });
      });
    },
  },
};
</script>

<style lang="sass"></style>
