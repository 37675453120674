<template>
  <v-card class="pa-5 anchor" v-if="annonce.online">
    <v-row>
      <v-col cols="12" md="3">
        <div class="ternary pa-2">
          <div v-for="image in annonce.images" :key="image.index">
            <v-img v-if="image.ordre == '1'" :src="image.url"></v-img>
            <div
              class="grey--text text-center font-weight-thin"
              v-if="image.ordre == '1'"
            >
              {{ image.titre }}
            </div>
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="9">
        <h5 class="headline mb-2">
          <span
            class="font-weight-bold"
            v-if="
              annonce.type == 'Location' ||
                annonce.type == 'Location avec DAB' ||
                annonce.type == 'Vente fonds'
            "
            >A LOUER :
          </span>
          <span class="font-weight-bold" v-if="annonce.type == 'Vente murs'"
            >A VENDRE :
          </span>
          {{ annonce.titre }} ({{ annonce.departement }})
        </h5>

        <v-divider></v-divider>

        <v-row>
          <v-col cols="12" md="12">
            <p v-if="annonce.sous_titre" class="mb-2">
              {{ annonce.sous_titre }}
            </p>
            <p v-if="annonce.ratio_prix" class="font-italic mb-2">
              Soit : {{ annonce.ratio_prix }} €/M²/an
            </p>
          </v-col>
          <v-col cols="12" md="12">
            <v-chip v-if="annonce.flash" color="error" class="justify-left">
              {{ annonce.flash }}
            </v-chip>

            <br />

            <v-chip
              class="mt-2 subtitle-1"
              v-if="annonce.parking"
              color="primary"
              ><v-icon>mdi-car-info</v-icon> Parking :
              {{ annonce.parking }} places
              {{ annonce.parking_details }}
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-card-actions class="">
      <v-spacer></v-spacer>
      <v-btn :to="`/annonce/${annonce.id}#anchor`" color="secondary" small
        >En savoir plus<v-icon class="ml-2" small
          >mdi-arrow-right</v-icon
        ></v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({}),
  props: {
    annonce: {},
  },
};
</script>

<style></style>
