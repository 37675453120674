<template>
  <div>
    <v-app id="keep" :dark="setTheme">
      <v-app-bar app clipped-left color="primary">
        <v-app-bar-nav-icon @click="drawer = !drawer" />
        <span class="title ml-3 mr-5 d-none d-md-block"
          >TCSO&nbsp;<span class="font-weight-light">Admin</span></span
        >

        <v-spacer></v-spacer>

        <div v-if="this.$store.getters.getUser">
          <span class="mr-3 subtitle-2">{{
            this.$store.getters.getUser.email
          }}</span>
          <v-btn @click="logOut" text>
            <span class="mr-2">Logout</span>
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </div>

        <div v-if="!this.$store.getters.getUser">
          <v-btn href="/login" text>
            <span class="mr-2">Login</span>
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </div>
      </v-app-bar>

      <v-navigation-drawer
        class="white--text"
        v-model="drawer"
        dark
        app
        clipped
        color="ternary"
      >
        <v-list dense class="ternary">
          <template>
            <v-list-item-title class="ml-10">
              <v-switch
                :label="`Dark Theme`"
                v-model="goDark"
                color="accent"
                @change="changeTheme"
              ></v-switch>
            </v-list-item-title>
            <v-divider class="white" />

            <v-list-item to="/" class="orange--text">
              <v-list-item-action>
                <v-icon class="primary--text">mdi-cellphone-link</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="primary--text">
                  Aller sur le site
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="white" />

            <v-list-item to="/admin-home">
              <v-list-item-action>
                <v-icon class="">mdi-home</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="">
                  Page D'accueil
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/admin-annonces">
              <v-list-item-action>
                <v-icon class="">mdi-text-box-plus-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="">
                  Annonces
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>

      <v-main>
        <v-container fluid class="">
          <v-row justify="center">
            <v-col class="">
              <router-view />
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </div>

  <!-- <v-row v-else class="justify-center mx-auto">
  <v-col cols="12" md="12" class="my-10 text-center">
        <v-img
          alt="TCSO Logo"
          class="mx-auto mb-10"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/tcso-b3ea7.appspot.com/o/main%2Ftest-tcso-bleu.png?alt=media&token=bd5cbfb6-437a-443b-92bf-f01c1177c731"
          transition="scale-transition"
          width="120"
          height="120"
        />
      <v-toolbar-title class="mb-10">TCSO</v-toolbar-title>
      <v-alert type="error">
      Vous n'avez pas l'autorisation d'accéder à cette page !
    </v-alert>
  </v-col>
  
</v-row> -->
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    goDark: "",
    user: "",
  }),
  created() {
    this.user = this.$store.getters.getUser;
  },
  computed: {
    setTheme() {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    changeTheme() {
      if (this.goDark == true) {
        return (this.$vuetify.theme.dark = true);
      } else {
        return (this.$vuetify.theme.dark = false);
      }
    },
    logOut() {
      this.$store.dispatch("logOut");
      this.$router.replace({ name: "Home" });
    },
  },
};
</script>

<style lang="sass">
#keep .v-navigation-drawer__border
  display: none
</style>
